import {Component, HostBinding, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import {ProcessRegistration} from "@/model/processRegistration/processRegistration";
import {Country} from "countrycitystatejson/src/countries-list";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-processor-management',
    templateUrl: './processor-management.component.html',
    styleUrls: ['./processor-management.component.scss']
})
export class ProcessorManagementComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    getProDetails: ProcessRegistration[] = []
    markId: string;
    isReadOnly: boolean = true;
    userId: string;
    countries: Country[];
    states: string[];
    cities: string[];
    imageShow: any;
    userType: string;
    isAdmin: boolean = false;

    constructor(
        private renderer: Renderer2, private datePipe: DatePipe,
        private toastr: ToastrService,
        private appService: AppService,
        private spinner: NgxSpinnerService
    ) {
        this.countries = this.appService.getCountries();
    }

    ngOnInit() {
        this.userId = localStorage.getItem('userId')
        this.userType = localStorage.getItem('userType')
        this.getProcessorRegistrationCreatedBy();
        this.processorDetails.disable();
    }

    processorDetails = new UntypedFormGroup({
        pt_processor_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorlegalname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processornickname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst3: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorstate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcountry: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorobjective: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoractive: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcreatedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcreateddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processormodifiedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processormodifieddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processor_emailid: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processor_phonenumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern(/^\d{10}$/)]),
        pt_processor_password: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        activeyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        deleteyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        ml_sequence_char: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_authenticate_code: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_website_link: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        latitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        longtitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        assetMetaData: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        assetFileType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        selectFile: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    })


    processorDetailsErrors = {
        pt_processorlegalname: "",
        pt_processornickname: "",
        pt_processoraddressst1: "",
        pt_processorcity: "",
        pt_processorstate: "",
        pt_processorcountry: "",
        pt_processor_phonenumber: "",
        pt_processorobjective: "",
        pt_processor_emailid: "",
        latitude: "",
        longtitude: "",
        pt_processor_password: "",
    }

    onSubmit() {
        this.formValidation()
    }

    formValidation() {
        this.processorDetailsErrors.pt_processorlegalname = "";
        this.processorDetailsErrors.pt_processornickname = "";
        this.processorDetailsErrors.pt_processoraddressst1 = "";
        this.processorDetailsErrors.pt_processorcity = "";
        this.processorDetailsErrors.pt_processorstate = "";
        this.processorDetailsErrors.pt_processorcountry = "";
        this.processorDetailsErrors.pt_processor_phonenumber = "";
        this.processorDetailsErrors.pt_processorobjective = "";
        this.processorDetailsErrors.pt_processor_emailid = "";
        this.processorDetailsErrors.latitude = "";
        this.processorDetailsErrors.longtitude = "";
        this.processorDetailsErrors.pt_processor_password = "";

        let hasError = false;

        if (this.processorDetails.get('pt_processorlegalname').invalid) {
            this.processorDetailsErrors.pt_processorlegalname = "*Enter Legal Name";
            hasError = true;
        }

        if (this.processorDetails.get('pt_processornickname').invalid) {
            this.processorDetailsErrors.pt_processornickname = "*Enter Nick Name";
            hasError = true;
        }

        if (this.processorDetails.get('pt_processoraddressst1').invalid) {
            this.processorDetailsErrors.pt_processoraddressst1 = "*Enter a Address";
            hasError = true;
        }

        if (this.processorDetails.get('pt_processorcity').invalid) {
            this.processorDetailsErrors.pt_processorcity = "*Select City";
            hasError = true;
        }

        if (this.processorDetails.get('pt_processorstate').invalid) {
            this.processorDetailsErrors.pt_processorstate = "*Select State";
            hasError = true;
        }

        if (this.processorDetails.get('pt_processorcountry').invalid) {
            this.processorDetailsErrors.pt_processorcountry = "*Select Country";
            hasError = true;
        }

        if (this.processorDetails.get("pt_processor_phonenumber").invalid) {
            if (this.processorDetails.get("pt_processor_phonenumber").errors?.["required"]) {
                this.processorDetailsErrors.pt_processor_phonenumber = "*Enter Phone Number";
            }
            if (this.processorDetails.get("pt_processor_phonenumber")?.getError("pattern")) {
                this.processorDetailsErrors.pt_processor_phonenumber = "*Enter 10-digit Phone number";
            }
            hasError = true;
        }

        if (this.processorDetails.get('pt_processorobjective').invalid) {
            this.processorDetailsErrors.pt_processorobjective = "*Enter Objective";
            hasError = true;
        }

        if (this.processorDetails.get('pt_processor_emailid').invalid) {
            this.processorDetailsErrors.pt_processor_emailid = "*Enter Email Id";
            hasError = true;
        }

        if (this.processorDetails.get('latitude').invalid) {
            this.processorDetailsErrors.latitude = "*Enter Latitude";
            hasError = true;
        }

        if (this.processorDetails.get('longtitude').invalid) {
            this.processorDetailsErrors.longtitude = "*Enter longitude";
            hasError = true;
        }

        if (!hasError) {
            this.updateRegistrion()
        }

    }

    numberOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    updateRegistrion() {
        this.spinner.show()
        console.log("id", this.markId)
        this.processorDetails.controls['pt_processor_id'].setValue(this.markId);
        this.processorDetails.controls['pt_processormodifiedby'].setValue(this.userId);
        this.processorDetails.controls['pt_processorcreatedby'].setValue(this.userId);
        this.processorDetails.controls['pt_processormodifieddate'].setValue(this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss' + '.' + new Date().getMilliseconds()));
        this.processorDetails.controls['activeyn'].setValue('Yes');
        this.processorDetails.controls['deleteyn'].setValue('No');
        console.log(this.processorDetails.value)
        this.appService.UpdateProcessorRegistrion(this.processorDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.imageShow = null;
                this.isReadOnly = true;
                this.spinner.hide();
                console.log("GetUpdateDetails::", data)
                this.alertShow();
            }, (error: any) => {
                this.spinner.hide()
                console.log("Something Went Wrong:", error)
            })
    }

    getProcessorRegistrationCreatedBy() {
        if (this.userType == "Super Admin") {
            this.spinner.show()
            this.appService.getProcessorRegistrationCreatedBy(this.userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.isAdmin = true;
                    this.spinner.hide()
                    this.getProDetails = data.sort((a, b) => {
                        const idA = parseInt(a.pt_processor_id.split('-')[1], 10);
                        const idB = parseInt(b.pt_processor_id.split('-')[1], 10);
                        return idB - idA;
                    })
                    console.log("GetSaveDetails::", data)
                }, (error: any) => {
                    this.spinner.hide()
                    console.log("Something Went Wrong:", error)
                })
        } else if (this.userType == "Processor") {
            this.spinner.show();
            const processorId = localStorage.getItem('userId');
            this.appService.getProcessorDetailsByProcessorId(processorId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.isAdmin = false;
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                    }
                    this.getProDetails = activeValues;
                }, (err: any) => {
                    console.log("something wrong :", err)
                    this.spinner.hide();
                })
        }
    }

    selectCheckbox(judgeid: string) {
        this.isReadOnly = true
        if (this.markId == judgeid) {
            this.markId = null
        } else {
            this.markId = judgeid
            this.MappingAlert();

        }
        console.log(this.markId)
    }

    setValueFunction() {
        const cureentValue = this.getProDetails.filter(item => item.pt_processor_id == this.markId)
        console.log("getvalue::", cureentValue)
        for (let pro of cureentValue) {
            this.states = this.appService.getStatesByCountry(pro.pt_processorcountry);
            this.cities = this.appService.getCitiesByState(pro.pt_processorcountry, pro.pt_processorstate);
            this.processorDetails.controls['pt_processorlegalname'].setValue(pro.pt_processorlegalname);
            this.processorDetails.controls['pt_processornickname'].setValue(pro.pt_processornickname);
            this.processorDetails.controls['pt_processoraddressst1'].setValue(pro.pt_processoraddressst1);
            this.processorDetails.controls['pt_processoraddressst2'].setValue(pro.pt_processoraddressst2);
            this.processorDetails.controls['pt_processoraddressst3'].setValue(pro.pt_processoraddressst3);
            this.processorDetails.controls['pt_processorcreateddate'].setValue(pro.pt_processorcreateddate)
            this.processorDetails.controls['pt_processormodifieddate'].setValue(pro.pt_processormodifieddate)
            this.processorDetails.controls['pt_processorcountry'].setValue(pro.pt_processorcountry);
            this.processorDetails.controls['pt_processorstate'].setValue(pro.pt_processorstate);
            this.processorDetails.controls['pt_processorcity'].setValue(pro.pt_processorcity);
            this.processorDetails.controls['pt_processor_phonenumber'].setValue(pro.pt_processor_phonenumber);
            this.processorDetails.controls['pt_processorobjective'].setValue(pro.pt_processorobjective);
            this.processorDetails.controls['ml_sequence_char'].setValue(pro.ml_sequence_char);
            this.processorDetails.controls['pt_authenticate_code'].setValue(pro.pt_authenticate_code);
            this.processorDetails.controls['pt_processoractive'].setValue(pro.pt_processoractive);
            this.processorDetails.controls['latitude'].setValue(pro.latitude);
            this.processorDetails.controls['longtitude'].setValue(pro.longtitude);
            this.processorDetails.controls['pt_website_link'].setValue(pro.pt_website_link);
            this.processorDetails.controls['pt_processor_password'].setValue(pro.pt_processor_password);
            this.processorDetails.controls['pt_processor_emailid'].setValue(pro.pt_processor_emailid);
            this.processorDetails.controls['deleteyn'].setValue(pro.deleteyn);
            this.processorDetails.controls['activeyn'].setValue(pro.activeyn);
        }
    }

    alertShow() {
        Swal.fire({
            title: "Details Updated Successfully...",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.spinner.show();
                this.markId = null;
                this.getProcessorRegistrationCreatedBy();
                this.processorDetails.reset();
                this.spinner.hide();
            }
        });
    }

    cencel() {
        window.location.reload()
    }

    MappingAlert() {
        Swal.fire({
            title: "Would you like to make any changes to the user details?",
            showDenyButton: true,
            confirmButtonColor: "#4fab69",
            denyButtonColor: "#c73636",
            confirmButtonText: "Yes",
            denyButtonText: "No",
            showClass: {
                popup: "animate_animated animate_fadeInDown",
            },
            hideClass: {
                popup: "animate_animated animate_fadeOutUp",
            },
            backdrop: false,
        }).then((result) => {
            if (result.isConfirmed) {
                this.processorDetails.enable();
                this.setValueFunction();
                this.isReadOnly = false;
                this.getImage()
            }
        });
    }

    selectCountry(event: any) {
        const country = event.target.value;
        console.log(country)
        if (country) {
            this.states = this.appService.getStatesByCountry(country);
        }
    }

    selectState(event: any) {
        const state = event.target.value;
        const country = this.processorDetails.get('pt_processorcountry').value
        console.log(state)
        if (state && country) {
            this.cities = this.appService.getCitiesByState(country, state);
        }
    }

    getImage() {
        this.appService.getImageById(this.markId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                this.spinner.hide()
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.imageShow = reader.result as string;
                    const [, base64Data] = this.imageShow.split(',');
                    const fileType = this.imageShow.split(';')[0].split('/')[1];
                    console.log("Base64 Data:", base64Data);
                    console.log("File Type:", fileType);
                    this.processorDetails.controls['assetFileType'].setValue(fileType);
                    this.processorDetails.controls['assetMetaData'].setValue(base64Data);
                }
                reader.readAsDataURL(blob);
            }, (error: any) => {
                this.spinner.hide()
                console.log("Something Went Wrong:", error)
            })
    }

    onFileChange(event: any) {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result as string;
                this.imageShow = base64String;
                const [, base64Data] = base64String.split(',');
                const fileType = base64String.split(';')[0].split('/')[1];
                console.log("Base64 Data:", base64Data);
                console.log("File Type:", fileType);
                this.processorDetails.controls['assetFileType'].setValue(fileType);
                this.processorDetails.controls['assetMetaData'].setValue(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    }
}
