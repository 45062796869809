import {Component, ElementRef, HostBinding, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AppService} from '@services/app.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import {ProcessRegistration} from "@/model/processRegistration/processRegistration";
import * as QRCode from "qrcode";
import {DatePipe} from "@angular/common";
import {ActivityByProcessor} from "@/model/activityDetails/activityByProcessor";

@Component({
    selector: 'app-qr-generation',
    templateUrl: './qr-generation.component.html',
    styleUrls: ['./qr-generation.component.scss']
})
export class QrGenerationComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    processorDetails: ProcessRegistration[] = [];
    activityByProcessor: ActivityByProcessor[] = [];
    userId: string;
    userType: string;
    processorId: string = "";
    qrCodeCount: number;
    qrCodeData: "http://localhost:4200/#/final-qr?batchCode=Wash-001";
    qrCodeUrl: string;
    @ViewChild('qrCodeContainer') qrCodeContainer: ElementRef;

    constructor(
        private renderer: Renderer2, private datePipe: DatePipe,
        private toastr: ToastrService,
        private appService: AppService,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {
        this.userType = localStorage.getItem('userType')
        this.getProcessorDetails();
    }

    getProcessorDetails() {
        if (this.userType == "Super Admin") {
            this.spinner.show();
            const createdById = localStorage.getItem('userId');
            this.appService.getProcessorRegistrationCreatedBy(createdById)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                    }
                    this.processorDetails = activeValues;
                }, (err: any) => {
                    console.log("something wrong :", err)
                    this.toastr.error("Something went wrong, Please try again later");
                    this.spinner.hide();
                })
        } else if (this.userType == "Processor") {
            this.spinner.show();
            const processorId = localStorage.getItem('userId');
            this.appService.getProcessorDetailsByProcessorId(processorId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                    }
                    this.processorDetails = activeValues;
                    this.processorId = this.processorDetails[0].pt_processor_id;
                }, (err: any) => {
                    console.log("something wrong :", err)
                    this.toastr.error("Something went wrong, Please try again later");
                    this.spinner.hide();
                })
        }
    }

    alertShow() {
        Swal.fire({
            title: "Details Updated Successfully...",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
            }
        });
    }

    getQrCount(event: any) {
        const value = event.target.value;
        this.qrCodeCount = value;
    }

    generateQRCodeUrl(url: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            QRCode.toDataURL(url, (error, qrCodeDataUrl) => {
                if (error) {
                    console.error('Error generating QR code:', error);
                    reject(error);
                } else {
                    resolve(qrCodeDataUrl);
                }
            });
        });
    }

    async generateQRCode() {
        const finalQrCodeData = "http://localhost:4200/#/final-qr?batchCode=Wash-001";

        try {
            const qrCodeDataUrls = await Promise.all(
                Array.from({length: this.qrCodeCount}, () => this.generateQRCodeUrl(finalQrCodeData))
            );

            // Render QR codes in the HTML container
            qrCodeDataUrls.forEach((dataUrl) => {
                const img = document.createElement('img');
                img.src = dataUrl;
                this.qrCodeContainer.nativeElement.appendChild(img);
            });

            this.printQr();
        } catch (error) {
            console.error('Error generating QR codes:', error);
        }
    }

    printQr() {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
      <html>
      <head>
          <title>Print QR Codes</title>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <style>
            @page {
              size: A4;
              margin: 0;
            }
            body {
              margin: 10px;
            }
            img {
              width: 140px; /* Adjust the width as needed */
              height: auto;
              margin: 5px;
            }
          </style>
      </head>
      <body>
        ${this.qrCodeContainer.nativeElement.innerHTML}
      </body>
      </html>
    `);
        printWindow.document.close();
        printWindow.onafterprint = function () {
            printWindow.close();
        };
    }

}
