import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;
    role: string;

    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {
    }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
        this.role = localStorage.getItem('userType');

        switch (this.role) {
            case "Processor":
                this.departmentMenu();
                break;
            case "Super Admin":
                this.menu = MENU;
                break;
        }
    }

    departmentMenu() {
        switch (this.role) {
            case "Processor": {
                if (localStorage.getItem('userId') == 'PPI-000028') {
                    this.menu = MENU;
                } else {
                    this.menu = processorMenu;
                }
                break;
            }
            default: {
                break;
            }
        }
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/main/dash']
    },
    {
        name: 'Processor Management',
        iconClasses: 'far fa-sun',
        path: ['/main/processor-management']
    },
    {
        name: 'Activity Management',
        iconClasses: 'fas fa-folder',
        children: [
            {
                name: 'Add Product / Activity',
                iconClasses: 'fas fa-layer-group',
                path: ['/main/add-activity']
            },
            {
                name: 'Activity Permanent QR',
                iconClasses: 'fas fa-qrcode',
                path: ['/main/activity-creation']
            },
            {
                name: 'Activity Mapping',
                iconClasses: 'fas fa-route',
                path: ['/main/activity-mapping']
            }
        ]
    },
    {
        name: 'Daily Activity Creation',
        iconClasses: 'fas fa-chart-line',
        path: ['/main/daily-activity-creation']
    },
    {
        name: 'Final Activity Creation',
        iconClasses: 'fas fa-print',
        path: ['/main/final-activity']
    },
];

export const processorMenu = [
    /*{
      name: 'Dashboard',
      iconClasses: 'fas fa-tachometer-alt',
      path: ['/']
    },*/
    {
        name: 'Processor Management',
        iconClasses: 'far fa-sun',
        path: ['/main/processor-management']
    },
    {
        name: 'Activity Management',
        iconClasses: 'fas fa-folder',
        children: [
            {
                name: 'Add Product / Activity',
                iconClasses: 'fa fa-plus-circle',
                path: ['/main/add-activity']
            },
            {
                name: 'Activity Permanent QR',
                iconClasses: 'fas fa-qrcode',
                path: ['/main/activity-creation']
            },
            {
                name: 'Activity Mapping',
                iconClasses: 'fas fa-route',
                path: ['/main/activity-mapping']
            }
        ]
    },
    {
        name: 'Daily Activity Creation',
        iconClasses: 'fas fa-chart-line',
        path: ['/main/daily-activity-creation']
    },
    /*{
      name: 'Final QR Create',
      iconClasses: 'fas fa-print',
      path: ['/main/qr-generation']
    },*/
];
