<div class="container-fluid">
    <div class="row">
        <div class="col-lg-4 mt-3">
            <form [formGroup]="activityMapDetails">
                <div class="form-floating mb-3">
                    <select class="form-select" formControlName="processorName" (change)="selectProcessor($event)">
                        <option selected hidden value="">Select Processor</option>
                        <option *ngFor="let pro of processorDetails" value="{{pro.pt_processor_id}}">
                            {{pro.pt_processorlegalname}}</option>
                    </select>
                    <label>Processor Name <span style="color: red">*</span></label>
                </div>
            </form>
        </div>

        <div class="col-lg-4 mt-3">
            <div class="form-floating mb-3">
                <select class="form-select" (change)="selectProduct($event)">
                    <option selected hidden value="">Select Product</option>
                    <option *ngFor="let pro of productByProcessor" value="{{pro.pt_Product_id}}">{{pro.pt_Productname}}
                    </option>
                </select>
                <label>Product Name<span style="color: red">*</span></label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="table-responsive table-style">
            <table class="table table-bordered">
                <thead class="text-center sticky-top">
                    <tr style="background-color: #e7f2f8">
                        <th>Activity Name</th>
                        <th>Previous Activity Name</th>
                        <th>Next Activity Name</th>
                        <th>Mapping</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" *ngFor="let act of activityByProcessor">
                        <td>{{act.pt_activityname}}</td>
                        <td>
                            <select class="form-select" (change)="buttonEnable($event, act)"
                                [(ngModel)]="act.pt_activity_prev_activity_id">
                                <option [value]="null" disabled>Select Previous Activity</option>
                                <option *ngFor="let name of activityByProcessor" [value]="name.pt_activity_id">
                                    {{name.pt_activityname}}</option>
                            </select>
                        </td>
                        <td>
                            <select class="form-select" (change)="buttonEnable($event, act)"
                                [(ngModel)]="act.pt_activity_next_activity_id">
                                <option [value]="null" disabled>Select Next Activity</option>
                                <option *ngFor="let name of activityByProcessor" [value]="name.pt_activity_id">
                                    {{name.pt_activityname}}</option>
                            </select>
                        </td>
                        <td>
                            <button *ngIf="act.buttonDisable" class="btn btn-success" type="button"
                                (click)="mapFunction(act)">Map
                            </button>
                            <button [disabled]="!act.buttonDisable" *ngIf="!act.buttonDisable" class="btn btn-danger"
                                type="button" (click)="mapFunction(act)">Map
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>