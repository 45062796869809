import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProcessRegistration} from '@/model/processRegistration/processRegistration';
import {HttpClient} from '@angular/common/http';
import {UntypedFormGroup} from "@angular/forms";
import {LoginResponse} from "@/modals/loginResponse";
import {LocalDataServiceService} from './local-data-service.service';
import {ActivityByProcessor} from "@/model/activityDetails/activityByProcessor";
import {NewActivityCreation, SecretCode} from '@/model/newActivityCreation/newActivityCreation';
import {DailyActivityDetails} from "@/model/dailyActivity/dailyActivityDetails";
import {ConfigService} from "@/config.service";
import * as countrycitystatejson from 'countrycitystatejson';
import {ProductDetails} from "@/model/productDetails/productDetails";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    private countryData = countrycitystatejson;

    constructor(private router: Router, private toastr: ToastrService, private http: HttpClient,
                private localData: LocalDataServiceService, private configService: ConfigService) {
    }

    /*async loginByAuth({email, password}) {
      try {
        const token = await Gatekeeper.loginByAuth(email, password);
        localStorage.setItem('token', token);
        await this.getProfile();
        this.router.navigate(['/']);
        this.toastr.success('Login success');
      } catch (error) {
        this.toastr.error(error.message);
      }
    }

    async registerByAuth({email, password}) {
      try {
        const token = await Gatekeeper.registerByAuth(email, password);
        localStorage.setItem('token', token);
        await this.getProfile();
        this.router.navigate(['/']);
        this.toastr.success('Register success');
      } catch (error) {
        this.toastr.error(error.message);
      }
    }

    async loginByGoogle() {
      try {
        const token = await Gatekeeper.loginByGoogle();
        localStorage.setItem('token', token);
        await this.getProfile();
        this.router.navigate(['/']);
        this.toastr.success('Login success');
      } catch (error) {
        this.toastr.error(error.message);
      }
    }

    async registerByGoogle() {
      try {
        const token = await Gatekeeper.registerByGoogle();
        localStorage.setItem('token', token);
        await this.getProfile();
        this.router.navigate(['/']);
        this.toastr.success('Register success');
      } catch (error) {
        this.toastr.error(error.message);
      }
    }

    async loginByFacebook() {
      try {
        const token = await Gatekeeper.loginByFacebook();
        localStorage.setItem('token', token);
        await this.getProfile();
        this.router.navigate(['/']);
        this.toastr.success('Login success');
      } catch (error) {
        this.toastr.error(error.message);
      }
    }

    async registerByFacebook() {
      try {
        const token = await Gatekeeper.registerByFacebook();
        localStorage.setItem('token', token);
        await this.getProfile();
        this.router.navigate(['/']);
        this.toastr.success('Register success');
      } catch (error) {
        this.toastr.error(error.message);
      }
    }*/

    async getProfile() {
        try {
            // this.user = await Gatekeeper.getProfile();
            this.user = localStorage.getItem('token');
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        this.user = null;
        this.router.navigate(['/login']);
    }

    loginAuth(loginForm: UntypedFormGroup) {
        return this.http.post<LoginResponse>(
            this.configService.getConfig().END_POINT + "/auth/login", loginForm
        )
    }

    //Save Processor Registrion Details

    saveProcessorRegistrion(userForm: any) {
        return this.http.post<ProcessRegistration>(
            this.configService.getConfig().END_POINT + "/User/AddProcessorDetails", userForm
        )
    }

    //Update Processor Registrion Details

    UpdateProcessorRegistrion(userForm: any) {
        return this.http.post<ProcessRegistration>(
            this.configService.getConfig().END_POINT + "/User/AddProcessorDetails/update", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Id Based Processor Registrion Details

    getProcessorRegistrionId(id: any) {
        return this.http.get<ProcessRegistration>(
            this.configService.getConfig().END_POINT + "/api/processor_registration/get/" + id,
            {headers: this.localData.getHeaders()}
        )
    }

//  //Save Activity Details Details

//  saveActivityDetails(userForm : any){
//   return this.http.post<ActivityDetails>(
//   this.configService.getConfig().END_POINT + "/api/activity_details/save" ,userForm ,
//   {headers: this.localData.getHeaders()}
//  )}

    //Get Processor Registrion Details

    getProcessorRegistrion() {
        return this.http.get<ProcessRegistration[]>(
            this.configService.getConfig().END_POINT + "/api/admin/processor_registration/getAll",
            {headers: this.localData.getHeaders()}
        )
    }

    getProcessorRegistrationCreatedBy(createdById: string) {
        return this.http.get<ProcessRegistration[]>(
            this.configService.getConfig().END_POINT + "/User/getProcessorDetailsUsingCtreatedBy/" + createdById,
            {headers: this.localData.getHeaders()}
        )
    }

    getProcessorDetailsByProcessorId(processorId: string) {
        return this.http.get<ProcessRegistration[]>(
            this.configService.getConfig().END_POINT + "/User/getProcessorDetails/" + processorId,
            {headers: this.localData.getHeaders()}
        )
    }

    getSecretCodeForDailyActivity() {
        return this.http.get<SecretCode>(
            this.configService.getConfig().END_POINT + "/User/getSecretCodePtActivity",
            {headers: this.localData.getHeaders()}
        )
    }

    getActivityDetails(processorId: string, productId: string) {
        return this.http.get<ActivityByProcessor[]>(
            this.configService.getConfig().END_POINT + "/User/getProcessorAvtivityMapDeta/" + processorId + "/" + productId,
            {headers: this.localData.getHeaders()}
        )
    }

    addActivityMapping(mappingForm: any) {
        return this.http.post(
            this.configService.getConfig().END_POINT + "/User/addActivityMapdetails", mappingForm,
            {headers: this.localData.getHeaders()}
        )
    }


    //Get Create Activity Details
    getCreateActivityDetails(processorId: string) {
        return this.http.get<NewActivityCreation[]>(
            this.configService.getConfig().END_POINT + "/User/getAddActivityDetailsUsingCB/" + processorId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Create Activity Details By Product
    getCreateActivityDetailsByProduct(processorId: string, productId: string) {
        return this.http.get<NewActivityCreation[]>(
            this.configService.getConfig().END_POINT + "/User/getAddActivityDetailsUsingCBByProduct/" + processorId + "/" + productId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Save Activity Details
    saveActivityDetails(userForm: any) {
        return this.http.post<NewActivityCreation>(
            this.configService.getConfig().END_POINT + "/User/insertAddActivityDetails", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    getCurrentActivityDetails(activeId: string) {
        return this.http.get<NewActivityCreation>(
            this.configService.getConfig().END_POINT + "/User/getActivityDetails/ActivityId/" + activeId,
            {headers: this.localData.getHeaders()}
        )
    }

    updateCurrentActivity(userForm: string) {
        return this.http.put<NewActivityCreation>(
            this.configService.getConfig().END_POINT + "/User/updateActivityDetails", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get All Activity By Date
    getAllActivityByDate(startDate: string, endDate: string) {
        return this.http.get<DailyActivityDetails[]>(
            this.configService.getConfig().END_POINT + "/User/getBetweenTime/" + startDate + "/" + endDate,
            {headers: this.localData.getHeaders()}
        )
    }

    //Save DailyActivity Details
    saveDailyActivity(userForm: any) {
        return this.http.post<DailyActivityDetails>(
            this.configService.getConfig().END_POINT + "/User/AddActivityDetails", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Update DailyActivity Details

    updateDailyActivity(userForm: any) {
        return this.http.put<DailyActivityDetails>(
            this.configService.getConfig().END_POINT + "/User/UpdateDailyActivityDetails", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //SecretCode Genenrate Api

    getSecretCode() {
        return this.http.get<SecretCode>(
            this.configService.getConfig().END_POINT + "/User/getSecretCodePtActivityDetails",
            {headers: this.localData.getHeaders()}
        )
    }

    getCountries() {
        return this.countryData.getCountries();
    }

    getStatesByCountry(countryShotName: string) {
        return this.countryData.getStatesByShort(countryShotName);
    }

    getCitiesByState(country: string, state: string) {
        return this.countryData.getCities(country, state);
    }

    getImageById(assetId: string) {
        return this.http.get(
            this.configService.getConfig().END_POINT + "/getAsset/" + assetId,
            {responseType: 'blob'}
        )
    }

    // get Processor Details for Final QR
    getProcessorDetailsFinalQr(processorId: string) {
        return this.http.get<ProcessRegistration>(
            this.configService.getConfig().END_POINT + "/finalQr/getProcessorDetails/" + processorId,
        )
    }

    // get Activity Details for Final QR
    getActivityDetailsFinalQr(createdById: string) {
        return this.http.get<NewActivityCreation[]>(
            this.configService.getConfig().END_POINT + "/finalQr/getAddActivityDetails/" + createdById,
        )
    }

    // get Daily Activity Details for Final QR
    getDailyActivityDetailsFinalQr(batchCode: string, productId: string) {
        return this.http.get<DailyActivityDetails[]>(
            this.configService.getConfig().END_POINT + "/finalQr/getBatchCodePtActivityDetailsByProduct/" + batchCode + "/" + productId,
        )
    }

    // get Daily Activity Details Using Created By
    getDailyActivityDetailsUsingCB(createdBy: string) {
        return this.http.get<DailyActivityDetails[]>(
            this.configService.getConfig().END_POINT + "/User/getActivityDetailsUsingCB/" + createdBy,
            {headers: this.localData.getHeaders()}
        )
    }

    // get Daily Activity Details Using Created By and Product ID
    getDailyActivityDetailsUsingCBAndProductId(createdBy: string, productId: string) {
        return this.http.get<DailyActivityDetails[]>(
            this.configService.getConfig().END_POINT + "/User/getActivityDetailsByCBAndProduct/" + createdBy + "/" + productId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Save Product
    saveProduct(userForm: UntypedFormGroup) {
        return this.http.post<ProductDetails>(
            this.configService.getConfig().END_POINT + "/User/AddProductDetails", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Product by product id
    getProductById(productId: String) {
        return this.http.get<ProductDetails[]>(
            this.configService.getConfig().END_POINT + "/User/getProductDetails/" + productId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Product using created by
    getProductUsingCB(createdBy: String) {
        return this.http.get<ProductDetails[]>(
            this.configService.getConfig().END_POINT + "/User/getProductDetailsUsingCB/" + createdBy,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Create Activity Details By Product
    getCreateActivityDetailsByProductForMapping(processorId: string, productId: string) {
        return this.http.get<ActivityByProcessor[]>(
            this.configService.getConfig().END_POINT + "/User/getAddActivityDetailsUsingCBByProduct/" + processorId + "/" + productId,
            {headers: this.localData.getHeaders()}
        )
    }
}
