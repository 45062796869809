<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
  <pf-image
    alt="AdminLTE Logo"
    class="brand-image"
    height="33"
    rounded
    src="assets/img/geo%20tracing%20favicon.png"
    style="opacity: 0.8"
    width="33"
  ></pf-image>
  <span class="brand-text font-weight-light" style="color: white">Geo Tracing</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
  <!-- Sidebar user (optional) -->
  <!--<div class="user-panel mt-3 pb-3 mb-3 d-flex">
    <div class="image">
    </div>
  </div>-->

  <!-- Sidebar Menu -->
  <nav class="mt-2" style="overflow-y: hidden; overflow-x: hidden">
    <ul
      class="nav nav-pills nav-sidebar flex-column"
      data-accordion="false"
      data-widget="treeview"
      role="menu"
    >
      <app-menu-item
        *ngFor="let item of menu"
        [menuItem]="item"
      ></app-menu-item>
    </ul>
  </nav>
</div>
