<!-- <div class="card card-outline card-primary">
  <div class="card-header text-center">
    <a [routerLink]="['/']" class="h1"><b>Admin</b>LTE</a>
  </div>
  <div class="card-body">
    <p class="login-box-msg">Register a new membership</p>

    <form (ngSubmit)="registerByAuth()" [formGroup]="registerForm">
      <div class="input-group mb-3">
        <input class="form-control" formControlName="email" placeholder="Email" type="email" />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-envelope"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input class="form-control" formControlName="password" placeholder="Password" type="password" />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input class="form-control" formControlName="retypePassword" placeholder="Retype password" type="password" />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <div class="icheck-primary">
            <input id="agreeTerms" name="terms" type="checkbox" value="agree" />
            <label for="agreeTerms">
              I agree to the <a href="#">terms</a>
            </label>
          </div>
        </div>
        <div class="col-5">
          <pf-button [block]="true" [disabled]="isFacebookLoading || isGoogleLoading" [loading]="isAuthLoading"
            [type]="'submit'">
            Register
          </pf-button>
        </div>
      </div>
    </form>

    <div class="social-auth-links text-center">
      <pf-button (click)="registerByFacebook()" [block]="true" [disabled]="isAuthLoading || isGoogleLoading"
        [loading]="isFacebookLoading" class="mb-2">
        <i class="fab fa-facebook mr-2"></i>
        Sign up using Facebook
      </pf-button>
      <pf-button (click)="registerByGoogle()" [block]="true" [disabled]="isAuthLoading || isFacebookLoading"
        [loading]="isGoogleLoading" theme="danger">
        <i class="fab fa-google mr-2"></i>
        Sign up using Google+
      </pf-button>
    </div>

    <a [routerLink]="['/login']" class="text-center">
      I already have a membership</a>
  </div>
</div> -->


<div class="container-fluid">
    <div class="card py-1 card-outline card-primary register-card-back">
        <div class="card-header text-center">
            <h3>Processor Registration</h3>
        </div>
        <div class="card-body text-center" style="align-items: center; align-content: center">
            <form [formGroup]="newRegistrion" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-1"></div>
                </div>
                <div class="row mt-">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="pt_processorlegalname"
                                   placeholder="Legal Name">
                            <label>Legal Name <span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processorlegalname">{{newRegistrionErrors.pt_processorlegalname}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4 ">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="pt_processornickname"
                                   placeholder="Nick Name">
                            <label>Nick Name <span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processornickname">{{newRegistrionErrors.pt_processornickname}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="pt_processoraddressst1"
                                   placeholder="Address">
                            <label>Address<span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processoraddressst1">{{newRegistrionErrors.pt_processoraddressst1}}</span>
                        </div>
                    </div>
                    <!--<div class="col-lg-4">
                      <div class="form-floating mb-3">
                        <input type="text" class="form-control" formControlName="pt_processoraddressst2" id="ad2" placeholder="Address ST2">
                        <label for="ad2">Address ST2</label>
                      </div>
                    </div>-->
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <select class="form-select" formControlName="pt_processorcountry"
                                    (change)="selectCountry($event)">
                                <option selected hidden value="">Select Country</option>
                                <option *ngFor="let country of countries"
                                        [value]="country.shortName">{{country.name}}</option>
                            </select>
                            <label>Select Country <span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processorcountry">{{newRegistrionErrors.pt_processorcountry}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <select class="form-select" formControlName="pt_processorstate"
                                    (change)="selectState($event)">
                                <option selected hidden value="">Select State</option>
                                <option *ngFor="let state of states" [value]="state">{{state}}</option>
                            </select>
                            <label>State <span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processorstate">{{newRegistrionErrors.pt_processorstate}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <select class="form-select" formControlName="pt_processorcity">
                                <option selected hidden value="">Select City</option>
                                <option *ngFor="let city of cities" [value]="city">{{city}}</option>
                            </select>
                            <label>City <span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processorcity">{{newRegistrionErrors.pt_processorcity}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="tel" class="form-control" formControlName="pt_processor_phonenumber"
                                   placeholder="Phone Number"
                                   (keypress)="numberOnly($event)" maxlength="10">
                            <label>Phone Number <span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processor_phonenumber">{{newRegistrionErrors.pt_processor_phonenumber}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="pt_processorobjective"
                                   placeholder="Objective Name">
                            <label>Objective <span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processorobjective">{{newRegistrionErrors.pt_processorobjective}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="ml_sequence_char"
                                   placeholder="ML/MT Code">
                            <label>Magic Label / Magic Tag Code </label>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="pt_website_link"
                                   placeholder="Website">
                            <label>Website</label>
                        </div>
                    </div>
                </div>
                <!--<div class="row">
                  <div class="col-lg-2"></div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" formControlName="pt_authenticate"
                        placeholder="Authentication Code">
                      <label>Authentication Code </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-floating mb-3">
                      <input type="text" class="form-control" value="Inactive" formControlName = "pt_processoractive" readonly>
                      &lt;!&ndash; <select class="form-select" formControlName="activeStatus">
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </select> &ndash;&gt;
                      <label>Active Status</label>
                    </div>
                  </div>
                </div>-->
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="latitude" placeholder="Latitude">
                            <label>Latitude <span class="text-danger">*</span> </label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.latitude">{{newRegistrionErrors.latitude}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="text" class="form-control" formControlName="longtitude"
                                   placeholder="Longitude">
                            <label>Longitude <span class="text-danger">*</span> </label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.longtitude">{{newRegistrionErrors.longtitude}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-4">
                        <div class="form-floating mb-3">
                            <input type="email" class="form-control" formControlName="pt_processor_emailid"
                                   placeholder="Email ID">
                            <label>Email ID <span class="text-danger">*</span></label>
                            <span class="text-danger d-flex justify-content-start"
                                  *ngIf="newRegistrionErrors.pt_processor_emailid">{{newRegistrionErrors.pt_processor_emailid}}</span>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-floating input-group-prepend">
                            <input type="password" class="form-control" formControlName="pt_processor_password"
                                   placeholder="Password" id="password">
                            <label>Password <span class="text-danger">*</span></label>
                            <span class="input-group-text"><i data-toggle="tooltip" data-placement="top"
                                                              title="Click to Show Password" aria-hidden="true"
                                                              class="toggle-password fa fa-lock"
                                                              (click)="togglePasswordVisibility()"
                                                              [ngClass]="{'fa-lock-open': isPasswordVisible, 'fa-lock': !isPasswordVisible}"
                                                              aria-hidden="true"></i></span>
                        </div>
                        <div class="row">
              <span class="text-danger" style="text-align: justify"
                    *ngIf="newRegistrionErrors.pt_processor_password">{{newRegistrionErrors.pt_processor_password}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2"></div>
                        <div class="col-lg-4">
                            <div class="form-floating input-group-prepend">
                                <input type="file" class="form-control" formControlName="selectedFile"
                                       (change)="onFileChange($event)">
                                <label>Organization Icon<span class="text-danger">*</span></label>
                            </div>
                            <div class="row">
              <span class="text-danger" style="text-align: justify"
                    *ngIf="newRegistrionErrors.selectedFile">{{newRegistrionErrors.selectedFile}}</span>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-floating input-group-prepend">
                                <input type="text" class="form-control" placeholder="About Me"
                                       formControlName="pt_processoraboutme">
                                <label>About Me<span class="text-danger">*</span></label>
                            </div>
                            <div class="row">
              <span class="text-danger" style="text-align: justify"
                    *ngIf="newRegistrionErrors.pt_processoraboutme">{{newRegistrionErrors.pt_processoraboutme}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3 mb-3">
                    <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                        <button class="btn btn-primary" type="submit" value="Save">Save</button>
                    </div>
                </div>
                <a [routerLink]="['/login']" class="text-center">
                    I already have a membership</a>
            </form>
        </div>
    </div>
    <!--  <div class="py-1"></div>-->
</div>
<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>