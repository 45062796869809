<div class="container-fluid">
    <form (ngSubmit)="onSubmit()" [formGroup]="dailyActivity">
        <div class="row">
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" id="name" formControlName="pt_processor_id"
                        (change)="selectProcessor($event)">
                        <option selected hidden value="">Select Processor</option>
                        <option *ngFor="let pro of processorDetails" value="{{pro.pt_processor_id}}">
                            {{pro.pt_processorlegalname}}</option>
                    </select>
                    <label for="name">Processor Name<span class="text-danger">*</span></label>
                    <span class="text-danger"
                        *ngIf="dailyActivityError.pt_processor_id">{{dailyActivityError.pt_processor_id}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" formControlName="pt_Product_id" (change)="selectProduct($event)">
                        <option selected hidden value="">Select Product</option>
                        <option *ngFor="let pro of productByProcessor" value="{{pro.pt_Product_id}}">
                            {{pro.pt_Productname}}</option>
                    </select>
                    <label for="name">Product Name<span class="text-danger">*</span></label>
                    <span class="text-danger"
                        *ngIf="dailyActivityError.pt_product_id">{{dailyActivityError.pt_product_id}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input type="text" class="form-control" formControlName="pt_activitybatchcode" autocomplete="off"
                           id="ptActivityBatchCode" placeholder="eg: ABC-0001" list="batchList"
                           (change)="batchCodeCheck($event)">
                    <datalist id="batchList">
                        <option *ngFor="let batch of existBatchCodeList" [value]="batch"></option>
                    </datalist>
                    <label for="short description">Enter Batch Code<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="dailyActivityError.pt_activitybatchcode">{{dailyActivityError.pt_activitybatchcode}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" disabled formControlName="pt_activityname">
                        <option selected hidden value="">Select Activity</option>
                        <option *ngFor="let act of activityByProcessor" value="{{act.pt_activity_id}}">
                            {{act.pt_activityname}}</option>
                    </select>
                    <label>Activity Name<span class="text-danger">*</span></label>
                    <span class="text-danger"
                        *ngIf="dailyActivityError.pt_activityname">{{dailyActivityError.pt_activityname}}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 mt-2">
                <div class="row col-md-12" style="margin: 1px; padding: 1px">
                    <div class="form-floating col-md-7 p-0">
                        <input type="text" (keypress)="OnlyNumbers($event)" class="form-control"
                            (input)="selectDuration($event)" formControlName="pt_activitydurartion" maxlength="3"
                            placeholder="Activity Duration">
                        <label>Duration <span class="text-danger">*</span></label>
                        <span class="text-danger"
                            *ngIf="dailyActivityError.pt_activitydurartion">{{dailyActivityError.pt_activitydurartion}}</span>
                    </div>
                    <div class="form-floating col-md-5 p-0">
                        <select class="form-select" formControlName="durationType" id="durationValue"
                            (input)="selectDuration($event)" style="background-color: #eaf1f1">
                            <option value="Hours">Hours</option>
                            <option value="Minutes">Minutes</option>
                        </select>
                        <label>Select</label>
                    </div>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating ">
                    <input type="datetime-local" [min]="currentDate" (change)="endDateCalculation($event)"
                        formControlName="pt_activitydailystarttime" class="form-control" id="start"
                        placeholder="Activity Start Time">
                    <label for="start">Activity Start Time <span class="text-danger">*</span></label>
                    <span class="text-danger"
                        *ngIf="dailyActivityError.pt_activitydailystarttime">{{dailyActivityError.pt_activitydailystarttime}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating ">
                    <input disabled type="datetime-local" class="form-control" formControlName="pt_activitydailyendtime"
                        id="end" placeholder="Activity End Time">
                    <label for="end">Activity End Time</label>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating ">
                    <input type="text" class="form-control" formControlName="pt_activityshortdesc"
                        id="short description" placeholder="Short Description">
                    <label for="short description">Enter Short Description <span class="text-danger">*</span>
                    </label>
                    <span class="text-danger"
                        *ngIf="dailyActivityError.pt_activityshortdesc">{{dailyActivityError.pt_activityshortdesc}}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-9 mt-2">
                <div class="form-floating ">
                    <input class="form-control" formControlName="pt_activitydesc" id="daily description"
                        placeholder="Activity Description">
                    <label for="daily description">Enter Description <span style="color: red">*</span></label>
                    <span class="text-danger"
                        *ngIf="dailyActivityError.pt_activitydesc">{{dailyActivityError.pt_activitydesc}}</span>
                </div>
            </div>

            <!--<div class="col-md-3">
                <div class="field-flex">
                    <div class="form-floating mb-3">
                        <input disabled type="password" class="form-control" formControlName="pt_activity_secretcode"
                               placeholder="secret code" id="code">
                        <label for="code">Secret Code</label>
                    </div>
                    <div class="form-floating mb-3">
                    <span style="background-color: #e9ecef" class="form-control" type="button">
                        <i class="fa fa-eye" id="eye" data-placement="top" title="Click to Show Password"
                           (click)="togglePasswordVisibility()" aria-hidden="true"
                           [ngClass]="{'fa-eye-slash': isPasswordVisible, 'fa-eye': !isPasswordVisible}"></i></span>
                    </div>
                </div>
            </div>-->

            <div class="col-md-3 mt-3 text-center">
                <button class="btn btn-danger ml-2" type="submit">{{this.isUpdate == true ? "Update" : "Save"}}</button>
            </div>
        </div>
    </form>

    <div class="col-md-12 mt-3" style="border-bottom: 2px solid #b3afaf"></div>

    <div class="col-md-12 row justify-content-center mt-2">
        <div class="col-md-3">
            <div class="form-floating">
                <input type="date" class="form-control" placeholder="start date" id="start-date"
                    (change)="dateBasedData($event)">
                <label for="start-date">Start Date</label>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-floating">
                <input type="date" class="form-control" placeholder="End date" id="end-date"
                    (change)="dateBasedData($event)">
                <label for="end-date">End Date</label>
            </div>
        </div>
    </div>

    <div class="col-md-12 mt-3 mb-3">
        <div class="table-responsive" style="height: 270px">
            <table class="table table-bordered">
                <thead class="text-center sticky-top" style="border-bottom: 1px solid black">
                    <tr style="background-color: #e7f2f8">
                        <th class="p-1"></th>
                        <th class="p-1">Activity Name</th>
                        <th class="p-1">Duration</th>
                        <th class="p-1">Daily Activity Start Time</th>
                        <th class="p-1">Daily Activity End Time</th>
                        <th class="p-1">Batch Code</th>
                        <th class="p-1">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-center" *ngFor="let act of activityByDate; let i = index">
                        <!-- <td>{{i + 1}}</td> -->
                        <td class="text-center">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox"
                                    (change)="selectCheckbox(act.pt_activity_id)"
                                    [checked]="activityId === act.pt_activity_id">
                                <label class="form-check-label"> </label>
                            </div>
                        </td>
                        <td>{{act.pt_activityname}}</td>
                        <td>{{act.pt_activitydurartion}}</td>
                        <td>{{act.pt_activitydailystarttime}}</td>
                        <td>{{act.pt_activitydailyendtime}}</td>
                        <td>{{act.pt_activitybatchcode}}</td>
                        <td>{{act.pt_activitydesc}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
