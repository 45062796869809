import { NewActivityCreation } from '@/model/newActivityCreation/newActivityCreation';
import { ProcessRegistration } from '@/model/processRegistration/processRegistration';
import { Component, ElementRef, HostBinding, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import * as QRCode from "qrcode";
import { ProductDetails } from "@/model/productDetails/productDetails";
import { DateTime } from 'luxon';
import { Router } from "@angular/router";

@Component({
    selector: 'app-activity-creation',
    templateUrl: './activity-creation.component.html',
    styleUrls: ['./activity-creation.component.scss']
})
export class ActivityCreationComponent implements OnInit {
    @ViewChild('qrcodeCanvas', { static: false }) qrcodeCanvas: ElementRef;
    public currentYear: string = DateTime.now().toFormat('y');
    showTextField: boolean = false;
    getProDetails: ProcessRegistration[] = [];
    getPersonalDetails: ProcessRegistration;
    destroy$: Subject<boolean> = new Subject<boolean>();
    LocalLegalName: string[] = []
    userType: string;
    userId: string;
    currentProcessorId: string;
    currentProductId: string;
    currentProductName: string;
    productName: any[] = [];
    activityName: any[] = [];
    getActivityDetails: NewActivityCreation[] = [];
    getProductDetails: ProductDetails[] = [];
    selectActiveName: string;
    selectActivieId: string;
    currentActivtyDetails: NewActivityCreation;
    isQRcode: boolean = true;
    currentProcessorName: any;
    updateDetails: NewActivityCreation;
    isViewShow = false;
    qrCodeUrl: string;
    isUpdate = false;
    currentActivityType: string;
    imageShow: any;
    states: string[];
    cities: string[];

    constructor(
        private renderer: Renderer2, private router: Router,
        private toastr: ToastrService,
        private appService: AppService,
        private spinner: NgxSpinnerService
    ) {
        this.states = this.appService.getStatesByCountry("IN")
    }

    processorDetails = new UntypedFormGroup({
        pt_processor_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorlegalname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processornickname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst3: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorstate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcountry: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorobjective: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoractive: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcreatedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcreateddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processormodifiedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processormodifieddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processor_emailid: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processor_phonenumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern(/^\d{10}$/)]),
        pt_processor_password: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        activeyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        deleteyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        ml_sequence_char: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_authenticate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_website: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
    })

    productDetails = new UntypedFormGroup({
        pt_Product_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Productname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    })

    activityDetails = new UntypedFormGroup({
        pt_processor_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_user_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Product_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivity_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activity_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivityname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activestatus: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivitycreateddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivitycreatedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivitymodifieddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivitymodifiedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        activeyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        deleteyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        userCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityduration: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activitydesc: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_shortdesc: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activity_seccode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityestematedlabelcount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activity_latitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activity_longtitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityaddressst1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityaddressst2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activitycity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activitystate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_dependancytype: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_previousallactivitycomplete: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityneedscan: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        selectedFile: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        assetFileType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        assetmetadata: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    activityDetailsErrors = {
        pt_addactivityname: "",
        pt_activitydesc: "",
        pt_shortdesc: "",
        pt_activity_latitude: "",
        pt_activity_longtitude: "",
        pt_activityaddressst1: "",
        pt_activityaddressst2: "",
        pt_activitycity: "",
        pt_activitystate: "",
        selectedFile: "",
    }


    ngOnInit() {
        this.userId = localStorage.getItem('userId')
        this.getProcessorRegistrion();
        this.activityDetails.disable();
    }

    formValidation() {
        this.activityDetailsErrors.pt_activitydesc = "";
        this.activityDetailsErrors.pt_shortdesc = "";
        this.activityDetailsErrors.pt_activity_longtitude = "";
        this.activityDetailsErrors.pt_activity_latitude = "";
        this.activityDetailsErrors.pt_activityaddressst1 = "";
        this.activityDetailsErrors.pt_activityaddressst2 = "";
        this.activityDetailsErrors.pt_activitycity = "";
        this.activityDetailsErrors.pt_activitystate = "";
        this.activityDetailsErrors.selectedFile = "";

        let hasError = false;
        if (this.activityDetails.get('pt_activitydesc').invalid) {
            this.activityDetailsErrors.pt_activitydesc = "*Enter Description";
            hasError = true;
        }

        if (this.activityDetails.get('pt_shortdesc').invalid) {
            this.activityDetailsErrors.pt_shortdesc = "*Enter Short Description";
            hasError = true;
        }

        if (this.activityDetails.get('pt_activity_longtitude').invalid) {
            this.activityDetailsErrors.pt_activity_longtitude = "*Enter Longitude";
            hasError = true;
        }

        if (this.activityDetails.get('pt_activity_latitude').invalid) {
            this.activityDetailsErrors.pt_activity_latitude = "*Enter Latitude";
            hasError = true;
        }
        if (this.activityDetails.get('pt_activityaddressst1').invalid) {
            this.activityDetailsErrors.pt_activityaddressst1 = "*Enter Address";
            hasError = true;
        }
        if (this.activityDetails.get('pt_activitystate').invalid) {
            this.activityDetailsErrors.pt_activitystate = "*Select State";
            hasError = true;
        }
        if (this.activityDetails.get('pt_activitycity').invalid) {
            this.activityDetailsErrors.pt_activitycity = "*Select City";
            hasError = true;
        }
        if (this.activityDetails.get('selectedFile').invalid && !this.isUpdate) {
            this.activityDetailsErrors.selectedFile = "*Upload the Logo";
            hasError = true;
        }

        if (!hasError) {
            console.log("updateDetails", this.activityDetails.value)
            this.updateCurrentActivity()
        }
    }

    currentActivity(name: string, id: string) {
        this.selectActiveName = name;
        this.selectActivieId = id;
        this.activityDetails.enable();
        this.getUserBasedActivity()
    }


    getProcessorRegistrion() {
        const userType = localStorage.getItem('userType');
        if (userType == "Super Admin") {
            this.spinner.show();
            this.appService.getProcessorRegistrationCreatedBy(this.userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                        this.getProDetails = activeValues;
                        this.LocalLegalName = this.getProDetails.map(item => item.pt_processorlegalname)
                    }
                    console.log("User Type:", localStorage.getItem('userType'));
                }, (err: any) => {
                    this.spinner.hide();
                    console.log("something wrong :", err)
                })
        } else if (userType == 'Processor') {
            this.spinner.show();
            this.appService.getProcessorDetailsByProcessorId(this.userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                        this.getProDetails = activeValues;
                        this.LocalLegalName = this.getProDetails.map(item => item.pt_processorlegalname)
                        this.processorDetails.controls['pt_processorlegalname'].setValue(this.getProDetails[0].pt_processorlegalname);
                        this.currentProcessorId = this.getProDetails[0].pt_processor_id;
                        this.currentProcessorName = this.getProDetails[0].pt_processorlegalname;
                        this.getAlreadyCreateProduct();
                    }
                    console.log("User Type:", localStorage.getItem('userType'));
                }, (err: any) => {
                    this.spinner.hide();
                    console.log("something wrong :", err)
                })
        }
    }

    getAlreadyCreateProduct() {
        this.spinner.show();
        this.appService.getProductUsingCB(this.currentProcessorId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Get Product::", data)
                this.spinner.hide();
                this.getProductDetails = data;
                this.productName = this.getProductDetails.map(item => ({
                    productName: item.pt_Productname,
                    productNumber: item.pt_Product_id
                }));
            }, (error: any) => {
                this.spinner.hide();
                console.log("Something Went Wrong:", error)
                this.toastr.error("Something went wrong, Please try again later")
            })
    }

    getAlreadyCreateActivity() {
        this.spinner.show();
        this.appService.getCreateActivityDetailsByProduct(this.currentProcessorId, this.currentProductId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                console.log("GetActivity::", data)
                if (data.length <= 0) {
                    this.alertShowForNoActivity();
                }
                // this.activityDetails.enable();
                this.getActivityDetails = data
                this.activityName = this.getActivityDetails.map(item => ({
                    activityName: item.pt_activityname,
                    activityNumber: item.pt_activity_id
                }));
                console.log("activityName::", this.activityName)
            }, (error: any) => {
                this.spinner.hide();
                console.log("Something Went Wrong:", error)
            })
    }

    toggleTextField() {
        this.showTextField = !this.showTextField;
    }


    ShowProcessorDetails(event: any) {
        const value = event.target.value;
        for (let pro of this.getProDetails) {
            if (value == pro.pt_processorlegalname) {
                this.currentProcessorName = pro.pt_processorlegalname
                this.processorDetails.controls['pt_processorlegalname'].setValue(pro.pt_processorlegalname)
                this.processorDetails.controls['pt_processor_phonenumber'].setValue(pro.pt_processor_phonenumber)
                this.processorDetails.controls['pt_processorcity'].setValue(pro.pt_processorcity)
                this.processorDetails.controls['pt_processorstate'].setValue(pro.pt_processorstate)
                this.processorDetails.controls['pt_processoractive'].setValue(pro.pt_processoractive)
                this.currentProcessorId = pro.pt_processor_id
                this.getAlreadyCreateProduct();
            }
        }
    }

    getAlreadyCreatedActivityByProduct(event: any) {
        console.log(event)
        const value = event.target.value;
        this.currentProductId = value;
        this.getAlreadyCreateActivity();
        for (let pro of this.getProductDetails) {
            if (this.currentProductId == pro.pt_Product_id) {
                this.currentProductName = pro.pt_Productname;
            }
        }
    }

    getUserBasedActivity() {
        this.spinner.show();
        this.appService.getCurrentActivityDetails(this.selectActivieId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("getUserActivity", data);
                this.spinner.hide();
                this.currentActivtyDetails = data;
                if (data.pt_activityaddressst1 == "" || data.pt_activityaddressst1 == null) {
                    this.isUpdate = false;
                    this.isQRcode = true;
                    this.getSecretCode();
                    this.activityDetails.controls['pt_activitycity'].disable();
                } else {
                    this.isUpdate = true;
                    this.isQRcode = false;
                    this.getLogo();
                }
                this.cities = this.appService.getCitiesByState('IN', data.pt_activitystate);
                this.activityDetails.controls['pt_activity_latitude'].setValue(data.pt_activity_latitude);
                this.activityDetails.controls['pt_activity_longtitude'].setValue(data.pt_activity_longtitude);
                this.activityDetails.controls['pt_activityaddressst1'].setValue(data.pt_activityaddressst1);
                this.activityDetails.controls['pt_activityaddressst2'].setValue(data.pt_activityaddressst2);
                this.activityDetails.controls['pt_activity_seccode'].setValue(data.pt_activity_seccode);
                this.activityDetails.controls['pt_activitycity'].setValue(data.pt_activitycity);
                this.activityDetails.controls['pt_activitystate'].setValue(data.pt_activitystate);
                this.activityDetails.controls['pt_activitydesc'].setValue(data.pt_activitydesc);
                this.activityDetails.controls['pt_shortdesc'].setValue(data.pt_shortdesc)
                this.activityDetails.controls['pt_user_id'].setValue(data.pt_user_id);
                this.activityDetails.controls['pt_Product_id'].setValue(data.pt_Product_id);
                this.activityDetails.controls['pt_activityduration'].setValue("0");
                this.activityDetails.controls['pt_addactivitymodifiedby'].setValue(data.pt_user_id);
                this.activityDetails.controls['pt_activityneedscan'].setValue('0')
                this.currentProcessorId = data.pt_processor_id;
                this.currentProductId = data.pt_Product_id;
            }, (err: any) => {
                console.log("Something wrong :", err);
                this.spinner.hide();
                this.toastr.error("Something went wrong, Please try again later");
            })

    }

    getLogo() {
        this.appService.getImageById(this.selectActivieId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                this.spinner.hide()
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.imageShow = reader.result as string;
                    const [, base64Data] = this.imageShow.split(',');
                    const fileType = this.imageShow.split(';')[0].split('/')[1];
                    console.log("Base64 Data:", base64Data);
                    console.log("File Type:", fileType);
                    this.activityDetails.controls['assetFileType'].setValue(fileType);
                    this.activityDetails.controls['assetmetadata'].setValue(base64Data);
                }
                reader.readAsDataURL(blob);
            }, (error: any) => {
                this.spinner.hide()
                console.log("Something Went Wrong:", error)
            })
    }

    updateCurrentActivity() {
        this.activityDetails.controls['pt_addactivity_id'].setValue(this.selectActivieId);
        this.activityDetails.controls['pt_activity_id'].setValue(this.selectActivieId);
        this.activityDetails.controls['pt_activityduration'].setValue("0");
        this.activityDetails.controls['pt_activityneedscan'].setValue('Yes')
        this.activityDetails.controls['pt_user_id'].setValue(this.currentProcessorId);
        this.activityDetails.controls['pt_processor_id'].setValue(this.currentProcessorId);
        this.activityDetails.controls['pt_Product_id'].setValue(this.currentProductId);
        this.activityDetails.controls['pt_addactivitymodifiedby'].setValue(this.currentProcessorId);
        this.activityDetails.controls['pt_activityaddressst2'].setValue("");
        this.spinner.show();
        console.log(this.activityDetails.value);
        this.appService.updateCurrentActivity(this.activityDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                if (!this.isUpdate) {
                    this.alertShow();
                } else {
                    this.alertUpdateShow();
                }
                this.updateDetails = data;
            }, (error: any) => {
                this.spinner.hide()
                this.toastr.error("Something went wrong, Please try again later");
                console.log("Something Went Wrong:", error)
            })
    }

    getSecretCode() {
        this.appService.getSecretCode()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("GetActivity::", data.secretCode)
                this.activityDetails.controls['pt_activity_seccode'].setValue(data.secretCode)
            }, (error: any) => {
                console.log("Something Went Wrong:", error)
            })
    }

    QRcodeGenration() {
        const QRData =
            "Processor ID :" + this.currentProcessorId + "\n" +
            "Processor Name :" + this.currentProcessorName + "\n" +
            "Activity ID  :" + this.currentActivtyDetails.pt_activity_id + "\n" +
            "Activity Name  :" + this.currentActivtyDetails.pt_activityname + "\n" +
            "Activity Description :" + this.currentActivtyDetails.pt_activitydesc + "\n" +
            "Activity Short Desc :" + this.currentActivtyDetails.pt_shortdesc;
        console.log("GetIdDetails::", this.currentActivtyDetails)
        this.generateQRCode(QRData)
    }

    alertUpdateShow() {
        Swal.fire({
            title: "Activity Details Updated Successfully. Do you want to download the QR Code?",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.QRcodeGenration();
            } else {
                window.location.reload();
            }
        });
    }

    alertShow() {
        Swal.fire({
            title: "Activity Details Saved Successfully. Do you want to download the QR Code?",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showCancelButton: true,
            cancelButtonText: "No",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.QRcodeGenration();
            } else {
                window.location.reload();
            }
        });
    }

    downloadQrCode() {
        const link = document.createElement('a');
        link.href = this.qrCodeUrl; // assuming this.qrCodeUrl is the URL of your QR code image
        link.download = 'activity qr code.png'; // specify the desired file name with the appropriate extension

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    close() {
        this.isViewShow = false;
        window.location.reload();
    }

    generateQRCodeUrl(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            QRCode.toDataURL(url, (error, qrCodeDataUrl) => {
                if (error) {
                    console.error("Error generating QR code:", error);
                    reject(error);
                } else {
                    this.qrCodeUrl = qrCodeDataUrl;
                    this.currentActivityType = this.currentActivtyDetails.pt_activityname
                    console.log("QR Code Data URL:", qrCodeDataUrl);
                    resolve();
                }
            });
        });
    }

    generateQRCode(finalQrCodeData) {

        this.generateQRCodeUrl(finalQrCodeData)
            .then(() => {
                this.spinner.hide();
                // this.isViewShow = true;
                this.printQr();
            })
            .catch((error) => {
                this.spinner.hide();
            });
    }

    selectState(event: any) {
        const state = event.target.value;
        const country = "IN";
        console.log(state)
        if (state && country) {
            this.cities = this.appService.getCitiesByState(country, state);
            this.activityDetails.controls['pt_activitycity'].enable();
        }
    }

    onFileChange(event: any) {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result as string;
                this.imageShow = base64String;
                const [, base64Data] = base64String.split(',');
                const fileType = base64String.split(';')[0].split('/')[1];
                console.log("Base64 Data:", base64Data);
                console.log("File Type:", fileType);
                this.activityDetails.controls['assetFileType'].setValue(fileType);
                this.activityDetails.controls['assetmetadata'].setValue(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    }

    printQr() {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
      <html>
      <head>
          <title>Print QR Codes</title>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <style>
            @page {
              size: A4;
              margin: 0;
            }
            .col-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
            .mt-5{margin-top:3rem!important}
            .mb-3{margin-bottom: 1rem !important;}
            .text-center {text-align: center !important;}
            .container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl{width:100%;padding-right:7.5px;padding-left:7.5px;margin-right:auto;margin-left:auto}@media (min-width:576px){.container,.container-sm{max-width:540px}}@media (min-width:768px){.container,.container-md,.container-sm{max-width:720px}}@media (min-width:992px){.container,.container-lg,.container-md,.container-sm{max-width:960px}}@media (min-width:1200px){.container,.container-lg,.container-md,.container-sm,.container-xl{max-width:1140px}}
            .footer-print {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  color: black;
}
          </style>
      </head>
      <body>
        <div class="container">
    <div class="col-12 mt-5 text-center">
        <h1>Processor Name : ${this.currentProcessorName}</h1>
    </div>
    <div class="col-12 mt-5 text-center">
        <h2>Product Name : ${this.currentProductName}</h2>
    </div>
    <div class="col-12 mt-5 text-center">
        <h2>Activity Name : ${this.selectActiveName}</h2>
    </div>
    <div class="col-12 mt-5 text-center">
        <img style="width: 500px" src="${this.qrCodeUrl}">
    </div>
</div>
<footer class="footer-print">
    <div class="text-center mb-3">
        <strong>
            <span>Copyright &copy; ${this.currentYear}</span>
            <a
                    href="https://varnikcloud.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style="margin: 0"
            >
                VarNikCloud.com</a
            >
            <span>.</span>
        </strong>
        <span> All rights reserved.</span>
    </div>
</footer>
      </body>
      </html>
    `);
        printWindow.document.close();
        printWindow.onload = function () {
            setTimeout(() => {
                printWindow.print();
            }, 1000);
            setTimeout(() => {
                printWindow.close();
                window.location.reload();
            }, 1000);
        };
    }

    downloadActivityQR(id: string) {
        this.selectActivieId = id;
        this.spinner.show();
        this.appService.getCurrentActivityDetails(this.selectActivieId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("getUserActivity", data);
                this.spinner.hide();
                this.currentActivtyDetails = data;
                this.selectActiveName = data.pt_activityname;
                this.QRcodeGenration();
            }, (err: any) => {
                this.spinner.hide();
                console.log("Something went wrong:", err)
            })
    }

    alertShowForNoActivity() {
        Swal.fire({
            title: "Please add activity for this product",
            confirmButtonText: "Okay"
        }).then((res) => {
            if (res.isConfirmed) {
                this.router.navigate(['/main/add-activity'])
            }
        })
    }

}

