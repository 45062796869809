import {NewActivityCreation} from '@/model/newActivityCreation/newActivityCreation';
import {ProcessRegistration} from '@/model/processRegistration/processRegistration';
import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppService} from '@services/app.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import {ProductDetails} from "@/model/productDetails/productDetails";

@Component({
    selector: 'app-add-activity',
    templateUrl: './add-activity.component.html',
    styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {
    @ViewChild('qrcodeCanvas', {static: false}) qrcodeCanvas: ElementRef;
    showTextFieldActivity: boolean = false;
    showTextFieldProduct: boolean = true;
    productNameDisabled: boolean = false;
    getProDetails: ProcessRegistration[] = [];
    destroy$: Subject<boolean> = new Subject<boolean>();
    LocalLegalName: string[] = []
    userType: string;
    userId: string;
    currentProcessorId: string;
    currentProductId: string;
    productName: any[] = [];
    activityName: any[] = [];
    getActivityDetails: NewActivityCreation[] = [];
    getProductDetails: ProductDetails[] = [];
    currentProcessorName: any;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private spinner: NgxSpinnerService
    ) {
    }

    processorDetails = new UntypedFormGroup({
        pt_processor_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorlegalname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processornickname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst3: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorstate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcountry: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorobjective: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoractive: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcreatedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcreateddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processormodifiedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processormodifieddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processor_emailid: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processor_phonenumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern(/^\d{10}$/)]),
        pt_processor_password: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        activeyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        deleteyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        ml_sequence_char: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_authenticate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_website: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
    })

    productDetails = new UntypedFormGroup({
        pt_processor_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_user_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Product_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Productname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Productstatus: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Productcreateddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Productcreatedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Productmodifieddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Productmodifiedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Productdesc: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        activeyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        deleteyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        assetMetaData: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        assetFileType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    })

    activityDetails = new UntypedFormGroup({
        pt_processor_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_user_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_Product_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivity_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activity_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivityname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activestatus: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivitycreateddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivitycreatedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivitymodifieddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_addactivitymodifiedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        activeyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        deleteyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        userCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityduration: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activitydesc: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_shortdesc: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activity_seccode: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityestematedlabelcount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activity_latitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activity_longtitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityaddressst1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityaddressst2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activitycity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activitystate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_dependancytype: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_previousallactivitycomplete: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_activityneedscan: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    productDetailsErrors = {
        pt_Productname: "",
        pt_Productdesc: "",
    }

    activityDetailsErrors = {
        pt_addactivityname: "",
        pt_activitydesc: "",
        pt_shortdesc: "",
        pt_activity_latitude: "",
        pt_activity_longtitude: "",
        pt_activityaddressst1: "",
        pt_activityaddressst2: "",
        pt_activitycity: "",
        pt_activitystate: "",
    }


    ngOnInit() {
        this.userId = localStorage.getItem('userId')
        this.getProcessorRegistrion()
    }

    getProcessorRegistrion() {
        const userType = localStorage.getItem('userType');
        if (userType == "Super Admin") {
            this.spinner.show();
            this.appService.getProcessorRegistrationCreatedBy(this.userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                        this.getProDetails = activeValues;
                        this.LocalLegalName = this.getProDetails.map(item => item.pt_processorlegalname)
                    }
                    console.log("User Type:", localStorage.getItem('userType'));
                }, (err: any) => {
                    this.spinner.hide();
                    console.log("something wrong :", err)
                })
        } else if (userType == 'Processor') {
            this.spinner.show();
            this.appService.getProcessorDetailsByProcessorId(this.userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                        this.getProDetails = activeValues;
                        this.LocalLegalName = this.getProDetails.map(item => item.pt_processorlegalname)
                        this.processorDetails.controls['pt_processorlegalname'].setValue(this.getProDetails[0].pt_processorlegalname);
                        this.currentProcessorId = this.getProDetails[0].pt_processor_id;
                        this.getAlreadyCreateProduct();
                    }
                    console.log("User Type:", localStorage.getItem('userType'));
                }, (err: any) => {
                    this.spinner.hide();
                    console.log("something wrong :", err)
                    this.toastr.error("Something went wrong, Please try again later")
                })
        }
    }

    getAlreadyCreateProduct() {
        this.spinner.show();
        this.appService.getProductUsingCB(this.currentProcessorId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Get Product::", data)
                this.spinner.hide();
                this.getProductDetails = data;
                this.productName = this.getProductDetails.map(item => ({
                    productName: item.pt_Productname,
                    productNumber: item.pt_Product_id,
                    productDesc: item.pt_Productdesc
                }));
                console.log("activityName::", this.productName)
            }, (error: any) => {
                this.spinner.hide();
                console.log("Something Went Wrong:", error)
                this.toastr.error("Something went wrong, Please try again later")
            })
    }

    getAlreadyCreateActivity() {
        this.spinner.show();
        this.appService.getCreateActivityDetailsByProduct(this.currentProcessorId, this.currentProductId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("GetActivity::", data)
                this.spinner.hide();
                this.getActivityDetails = data
                this.activityName = this.getActivityDetails.map(item => ({
                    activityName: item.pt_activityname,
                    activityNumber: item.pt_activity_id
                }));
                console.log("activityName::", this.activityName)
            }, (error: any) => {
                this.spinner.hide();
                console.log("Something Went Wrong:", error)
                this.toastr.error("Something went wrong, Please try again later")
            })
    }

    getAlreadyCreatedActivityByProduct(event: any) {
        const value = event.target.value;
        this.currentProductId = value;
        this.getAlreadyCreateActivity();
    }

    /*toggleTextField1() {
        this.showTextFieldProduct = !this.showTextFieldProduct;
    }

    toggleTextField2() {
        this.showTextFieldActivity = !this.showTextFieldActivity;
    }*/


    ShowProcessorDetails(event: any) {
        const value = event.target.value;
        for (let pro of this.getProDetails) {
            if (value == pro.pt_processorlegalname) {
                this.currentProcessorName = pro.pt_processorlegalname
                this.processorDetails.controls['pt_processorlegalname'].setValue(pro.pt_processorlegalname)
                this.processorDetails.controls['pt_processor_phonenumber'].setValue(pro.pt_processor_phonenumber)
                this.processorDetails.controls['pt_processorcity'].setValue(pro.pt_processorcity)
                this.processorDetails.controls['pt_processorstate'].setValue(pro.pt_processorstate)
                this.processorDetails.controls['pt_processoractive'].setValue(pro.pt_processoractive)
                this.currentProcessorId = pro.pt_processor_id;
                this.getAlreadyCreateProduct();
            }
        }
    }

    saveProduct() {
        this.productDetailsErrors.pt_Productname = "";
        this.productDetailsErrors.pt_Productdesc = "";

        let hasError = false;

        if (this.productDetails.get('pt_Productname').invalid) {
            this.productDetailsErrors.pt_Productname = "*Enter Product Name";
            hasError = true;
        }
        if (this.productDetails.get('pt_Productdesc').invalid) {
            this.productDetailsErrors.pt_Productdesc = "*Enter Product Description";
            hasError = true;
        }
        if (!hasError) {
            console.log("d")
            this.productDetails.controls['activeyn'].setValue("Yes");
            this.productDetails.controls['deleteyn'].setValue("No");
            this.productDetails.controls['pt_processor_id'].setValue(this.currentProcessorId);
            this.productDetails.controls['pt_user_id'].setValue(this.currentProcessorId);
            this.productDetails.controls['pt_Productcreatedby'].setValue(this.currentProcessorId);
            this.productDetails.controls['pt_Productstatus'].setValue("Active");
            console.log("save Product::", this.productDetails.value)
            this.spinner.show();
            this.appService.saveProduct(this.productDetails.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.spinner.hide()
                    this.currentProductId = data.pt_Product_id;
                    this.toastr.success("Product created Successfully, Please add Activity");
                    this.productNameDisabled = true;
                    this.productDetails.controls['pt_Productname'].disable();
                    this.productDetails.controls['pt_Productdesc'].disable();
                    this.showTextFieldActivity = true;
                    this.getAlreadyCreateProduct()
                    console.log("Saved Product::", data)
                }, (error: any) => {
                    this.spinner.hide()
                    this.toastr.error("Something went wrong, Please try again later");
                    console.log("Something Went Wrong:", error)
                })
        }
    }

    saveActivity() {
        this.activityDetailsErrors.pt_addactivityname = "";

        let hasError = false;

        if (this.activityDetails.get('pt_addactivityname').invalid) {
            this.activityDetailsErrors.pt_addactivityname = "*Enter an Activity Name";
            hasError = true;
        }
        if (!hasError) {
            console.log("d")
            this.activityDetails.controls['activeyn'].setValue("Yes");
            this.activityDetails.controls['deleteyn'].setValue("No");
            this.activityDetails.controls['pt_processor_id'].setValue(this.currentProcessorId);
            this.activityDetails.controls['pt_user_id'].setValue(this.currentProcessorId);
            this.activityDetails.controls['pt_Product_id'].setValue(this.currentProductId);
            this.activityDetails.controls['pt_addactivitycreatedby'].setValue(this.currentProcessorId);
            this.activityDetails.controls['pt_activestatus'].setValue("Active");
            console.log("saveActivity::", this.activityDetails.value)
            this.spinner.show();
            this.appService.saveActivityDetails(this.activityDetails.value)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    this.spinner.hide()
                    this.toastr.success("Activity Name Added Successfully");
                    this.activityDetails.controls['pt_addactivityname'].setValue('');
                    this.getAlreadyCreateActivity()
                    this.alertForActivity();
                    console.log("GetIdDetails::", data)
                }, (error: any) => {
                    this.spinner.hide()
                    this.toastr.error("Something went wrong, Please try again later");
                    console.log("Something Went Wrong:", error)
                })
        }
    }

    alertForActivity() {
        Swal.fire({
            title: "Do you want to add more Activity?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: `No`
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.activityDetails.controls['pt_addactivityname'].setValue('');
            } else if (result.isDenied) {
                this.showTextFieldActivity = false;
                this.productNameDisabled = false;
                this.productDetails.controls['pt_Productname'].enable();
                this.productDetails.controls['pt_Productname'].setValue('');
                this.productDetails.controls['pt_Productdesc'].enable();
                this.productDetails.controls['pt_Productdesc'].setValue('');
            }
        });
    }

    addActivity(productId: string, productName: string, desc: string) {
        this.currentProductId = productId;
        this.showTextFieldProduct = true;
        this.productDetails.controls['pt_Productname'].setValue(productName);
        this.productDetails.controls['pt_Productname'].disable();
        this.productDetails.controls['pt_Productdesc'].setValue(desc);
        this.productDetails.controls['pt_Productdesc'].disable();
        this.activityDetails.controls['pt_addactivityname'].setValue('');
        this.productNameDisabled = true;
        this.showTextFieldActivity = true;
        this.getAlreadyCreateActivity();
    }

}

