<div class="container-fluid">
    <div class="row">
        <div class="col-md-2">
            <div class="col-md-12 row">
                <h5 class=" mt-2 text-bold">Processor</h5>
            </div>
            <form [formGroup]="processorDetails">
                <div class="col-md-12">
                    <div class="form-floating mb-3">
                        <select class="form-select" formControlName="pt_processorlegalname"
                                (click)="ShowProcessorDetails($event)">
                            <option selected hidden value="">Select Processor</option>
                            <option *ngFor="let name of LocalLegalName" [value]="name">{{name}}</option>
                        </select>
                        <label>Processor Name<span style="color: red">*</span></label>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-md-6">
            <div class="col-md-12">
                <!--<h5 class=" mt-2 mb-0 text-bold">Add Product <button class="btn-sm" style="color: red" (click)="toggleTextField1()">
                    <i class="fa fa-plus"></i>
                </button></h5>
                <span class="text-danger" style="font-size: 12px !important;">*Note: Click "<i class="fa fa-plus"></i>" Button to add Product</span>-->
                <h5 class=" mt-2">Add Product</h5>
            </div>
            <form [formGroup]="productDetails">
                <div class="row">
                    <div *ngIf="showTextFieldProduct" class="col-5">
                        <div class="form-floating">
                            <input class="form-control" type="text" placeholder="Product"
                                   formControlName="pt_Productname">
                            <label>Product Name</label>
                            <span class="text-danger"
                                  *ngIf="productDetailsErrors.pt_Productname">{{productDetailsErrors.pt_Productname}}</span>
                        </div>
                    </div>
                    <div *ngIf="showTextFieldProduct" class="col-5">
                        <div class="form-floating">
                            <input class="form-control" type="text" placeholder="Product"
                                   formControlName="pt_Productdesc">
                            <label>Product Description</label>
                            <span class="text-danger"
                                  *ngIf="productDetailsErrors.pt_Productdesc">{{productDetailsErrors.pt_Productdesc}}</span>
                        </div>
                    </div>
                    <div class="col-1">
                        <button *ngIf="showTextFieldProduct" [disabled]="productNameDisabled" class="btn btn-success mt-2" type="button"
                                (click)="saveProduct()"><i class="fa fa-check"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="col-md-3">
            <div class="col-md-12">
                <!--<h5 class=" mt-2 mb-0 text-bold">Add Activity <button [disabled]="!productNameDisabled" class="btn-sm" style="color: red" (click)="toggleTextField2()">
                    <i class="fa fa-plus"></i>
                </button></h5>
                <span class="text-danger" style="font-size: 12px !important;">*Note: Click "<i class="fa fa-plus"></i>" Button to add Activity</span>-->
                <h5 class=" mt-2">Add Activity</h5>
            </div>
            <form [formGroup]="activityDetails">
                <div class="row">
                    <div *ngIf="showTextFieldActivity" class="col-11">
                        <div class="form-floating">
                            <input class="form-control" type="text" placeholder="Activity"
                                   formControlName="pt_addactivityname">
                            <label>Activity Name</label>
                            <span class="text-danger"
                                  *ngIf="activityDetailsErrors.pt_addactivityname">{{activityDetailsErrors.pt_addactivityname}}</span>
                        </div>
                    </div>
                    <div class="col-1 text-center">
                        <button *ngIf="showTextFieldActivity" [disabled]="!productNameDisabled" class="btn btn-success mt-2" type="button"
                                (click)="saveActivity()"><i class="fa fa-check"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row justify-content-around">
        <div class="col-5">
            <span class="text-danger" style="font-size: 14px !important;">Note: If you want add more activity, click the product name</span>
            <div class="container-fluid activity-card mb-3">
                <div class="col-md-12 mb-2"></div>
                <div class="col-md-12">
                    <h4 class="mb-3 text-bold">Added Products</h4>
                </div>
                <div class="row">
                    <div class="table-responsive table-style">
                        <table class="table table-bordered">
                            <thead class="text-center sticky-top">
                            <tr style="background-color: #e7f2f8">
                                <th>No</th>
                                <th>Product Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="text-center" *ngFor="let pro of productName; let i = index">
                                <td>{{i + 1}}</td>
                                <td (click)="addActivity(pro.productNumber, pro.productName, pro.productDesc)">{{pro.productName}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-5 mt-4">
            <div class="container-fluid activity-card">
                <div class="col-md-12 mb-2"></div>
                <div class="col-md-12">
                    <h4 class="mb-3 text-bold">Added Activities</h4>
                </div>
                <div class="row">
                    <div class="table-responsive table-style">
                        <table class="table table-bordered">
                            <thead class="text-center sticky-top">
                            <tr style="background-color: #e7f2f8">
                                <th>No</th>
                                <th>Activity Name</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="text-center" *ngFor="let act of activityName; let i = index">
                                <td>{{i + 1}}</td>
                                <td>{{act.activityName}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>