<pf-dropdown hide-arrow class="user-menu">
  <div slot="button">
    <a class="nav-link">
      <pf-image
              [src]="user.picture"
              fallback-src="assets/img/default-profile.png"
              class="user-image-small"
              width="25"
              height="25"
              alt="User Image"
              rounded
      ></pf-image>
    </a>
  </div>

  <div slot="menu">
    <li class="user-header bg-primary">
      <pf-image
              [src]="user.picture"
              fallback-src="assets/img/default-profile.png"
              class="user-image-big"
              alt="User Image"
              width="90"
              height="90"
              rounded
      ></pf-image>

      <p>
        <span>Role : {{user}}</span>
        <!--<small>
            <span class="mr-2">Member since</span>
            <span>{{ formatDate(user.createdAt) }}</span>
        </small>-->
      </p>
    </li>
    <!-- Menu Body -->
    <!--<li class="user-body">
        <div class="row">
            <div class="col-4 text-center">
                <a [routerLink]="['/']">Followers</a>
            </div>
            <div class="col-4 text-center">
                <a [routerLink]="['/']">Sales</a>
            </div>
            <div class="col-4 text-center">
                <a [routerLink]="['/']">Friends</a>
            </div>
        </div>
        &lt;!&ndash; /.row &ndash;&gt;
    </li>-->
    <!-- Menu Footer-->
    <li class="user-footer d-flex justify-content-center">
      <!--            <a class="btn btn-default btn-flat ml-3"></a>-->
      <a (click)="logout()" class="btn btn-default">
        Sign out
      </a>
    </li>
  </div>
</pf-dropdown>