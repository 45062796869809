<div class="container-fluid">
    <form (ngSubmit)="onSubmit()" [formGroup]="dailyActivity">
        <div class="row">
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" id="name" formControlName="pt_processor_id"
                            (change)="selectProcessor($event)">
                        <option selected hidden value="">Select Processor</option>
                        <option *ngFor="let pro of processorDetails"
                                value="{{pro.pt_processor_id}}">{{pro.pt_processorlegalname}}</option>
                    </select>
                    <label for="name">Processor Name<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="dailyActivityError.pt_processor_id">{{dailyActivityError.pt_processor_id}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" formControlName="pt_Product_id"
                            (change)="selectProduct($event)">
                        <option selected hidden value="">Select Product</option>
                        <option *ngFor="let pro of productByProcessor"
                                value="{{pro.pt_Product_id}}">{{pro.pt_Productname}}</option>
                    </select>
                    <label for="name">Product Name<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="dailyActivityError.pt_product_id">{{dailyActivityError.pt_product_id}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input type="text" class="form-control text-cap" formControlName="pt_activitybatchcode" autocomplete="off"
                           id="ptActivityBatchCode" placeholder="eg: ABC-0001" list="batchList"
                           (change)="batchCodeCheck($event)">
                    <datalist id="batchList">
                        <option *ngFor="let batch of existBatchCodeList" [value]="batch"></option>
                    </datalist>
                    <label>Enter Batch Code<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="dailyActivityError.pt_activitybatchcode">{{dailyActivityError.pt_activitybatchcode}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" disabled formControlName="pt_activityname">
                        <option selected hidden value="">Select Activity</option>
                        <option *ngFor="let act of showActivity"
                                value="{{act.pt_activity_id}}">{{act.pt_activityname}}</option>
                    </select>
<!--                    <input class="form-control" disabled formControlName="pt_activityname" placeholder="Activity">-->
                    <label>Activity Name<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="dailyActivityError.pt_activityname">{{dailyActivityError.pt_activityname}}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 mt-2">
                <div class="form-floating ">
                    <input type="datetime-local" [min]="currentDate" (change)="startDateValidation($event)"
                           formControlName="pt_activitydailystarttime" class="form-control" id="start"
                           placeholder="Activity Start Time">
                    <label for="start">Activity Start Time <span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="dailyActivityError.pt_activitydailystarttime">{{dailyActivityError.pt_activitydailystarttime}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-floating ">
                    <input type="datetime-local" [min]="currentDate" class="form-control" formControlName="pt_activitydailyendtime"
                           id="end" placeholder="Activity End Time" (change)="endDateValidation($event)">
                    <label for="end">Activity End Time</label>
                    <span class="text-danger"
                          *ngIf="dailyActivityError.pt_activitydailyendtime">{{dailyActivityError.pt_activitydailyendtime}}</span>
                </div>
            </div>

            <div class="col-md-6 mt-2">
                <div class="form-floating ">
                    <input class="form-control" formControlName="pt_activitydesc" id="daily description"
                           placeholder="Activity Description">
                    <label for="daily description">Enter Description <span
                            style="color: red">*</span></label>
                    <span class="text-danger"
                          *ngIf="dailyActivityError.pt_activitydesc">{{dailyActivityError.pt_activitydesc}}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div *ngIf="!labelCountInput" class="col-md-12 mt-3 text-center">
                <button class="btn btn-danger ml-2" type="submit">{{this.isUpdate == true ? "Update" : "Save"}}</button>
            </div>

            <div *ngIf="labelCountInput" class="col-md-12 mt-3 text-center">
                <div class="row col-md-12 justify-content-center">
<!--                <input class="col-2 form-control" placeholder="Enter Label Count">-->
                    <div class="form-floating col-2">
                        <input class="form-control" (keypress)="OnlyNumbers($event)" (input)="enableGenerateButton($event)"
                               placeholder="Enter Label Count">
                        <label for="daily description">Enter Label Count <span
                                style="color: red">*</span></label>
                    </div>
                <button [disabled]="!generateButtonEnable" class="btn btn-primary col-2" (click)="navigateLabelPrint()">Generate Label</button>
                </div>
            </div>
        </div>
    </form>

    <div class="col-md-12 mt-3" style="border-bottom: 2px solid #b3afaf"></div>

    <div class="col-md-12 row justify-content-center mt-2">
        <div class="col-md-3">
            <div class="form-floating">
                <input type="date" class="form-control" placeholder="start date" id="start-date"
                       (change)="dateBasedData($event)">
                <label for="start-date">Start Date</label>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-floating">
                <input type="date" class="form-control" placeholder="End date" id="end-date"
                       (change)="dateBasedData($event)">
                <label for="end-date">End Date</label>
            </div>
        </div>
    </div>

    <div class="col-md-12 mt-3 mb-3">
        <div class="table-responsive" style="height: 270px">
            <table class="table table-bordered">
                <thead class="text-center sticky-top" style="border-bottom: 1px solid black">
                <tr style="background-color: #e7f2f8">
                    <th class="p-1"></th>
                    <th class="p-1">Activity Name</th>
                    <th class="p-1">Daily Activity Start Time</th>
                    <th class="p-1">Daily Activity End Time</th>
                    <th class="p-1">Batch Code</th>
                    <th class="p-1">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center" *ngFor="let act of activityByDate; let i = index">
                    <!-- <td>{{i + 1}}</td> -->
                    <td class="text-center">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox"
                                   (change)="selectCheckbox(act.pt_activity_id)"
                                   [checked]="activityId === act.pt_activity_id">
                            <label class="form-check-label"> </label>
                        </div>
                    </td>
                    <td>{{act.pt_activityname}}</td>
                    <td>{{act.pt_activitydailystarttime}}</td>
                    <td>{{act.pt_activitydailyendtime}}</td>
                    <td>{{act.pt_activitybatchcode}}</td>
                    <td>{{act.pt_activitydesc}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
