import { Component, HostListener, OnInit } from '@angular/core';
import { AppService } from "@services/app.service";
import { Subject, takeUntil } from "rxjs";
import { ProcessRegistration } from "@/model/processRegistration/processRegistration";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { DailyActivityDetails } from "@/model/dailyActivity/dailyActivityDetails";
import { NgxSpinnerService } from "ngx-spinner";
import { NewActivityCreation } from "@/model/newActivityCreation/newActivityCreation";
import { DateTime } from 'luxon';

@Component({
    selector: 'app-final-qr',
    templateUrl: './final-qr.component.html',
    styleUrls: ['./final-qr.component.scss']
})
export class FinalQrComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    processorName: string;
    address: string;
    city: string;
    state: string;
    country: string;
    processorId: string;
    ProductId: string;
    imageShow: any;
    latitude: number;
    longitude: number;
    mapView: boolean = false;
    mapUrl: any;
    dailyData: any[] = [];
    allActivityByProcessor: NewActivityCreation[] = [];
    processorDetails: ProcessRegistration | undefined;
    batchCode: string;
    urlProductId: string;
    addValues: number[] = [];
    evenValues: number[] = [];
    imageUrls: { [activityId: string]: string } = {};
    public currentYear: string = DateTime.now().toFormat('y');

    constructor(private appService: AppService, private toaster: ToastrService,
        private sanitizer: DomSanitizer, private route: ActivatedRoute,
        private spinner: NgxSpinnerService) {
        this.addValues = [1, 3, 5, 7, 9];
        this.evenValues = [0, 2, 4, 6, 8];
    }

    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        const windowHeight = window.innerHeight;
        const body = document.body;
        const html = document.documentElement;
        const documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const scrollOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const scrollButton = document.querySelector('.scroll-to-top-btn');

        if (scrollButton) {
            if (documentHeight - windowHeight - scrollOffset < 100) {
                scrollButton.classList.add('show-scroll-btn');
            } else {
                scrollButton.classList.remove('show-scroll-btn');
            }
        }
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.batchCode = params['batchCode'];
            this.urlProductId = params['productId'];
            console.log(this.batchCode + this.urlProductId)
        });
        this.getDailyDataByBatchId(this.batchCode, this.urlProductId);
    }

    getDailyDataByBatchId(batchCode: string, productId: string) {
        this.spinner.show();
        this.appService.getDailyActivityDetailsFinalQr(batchCode, productId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("Daily data :", data);
                this.dailyData = data;
                this.processorId = this.dailyData[0].pt_processor;
                this.ProductId = this.dailyData[0].pt_Product_id
                this.getProcessorDetails(this.processorId);
                this.getActivityDetailsByProcessor(this.processorId);
                this.getImage(this.processorId);
            }, (err: any) => {
                console.log("Something wrong :", err)
                this.spinner.hide();
                this.toaster.error("Something went wrong, Please try again later")
            })
    }

    getActivityDetailsByProcessor(processorId: string) {
        this.spinner.show();
        this.appService.getActivityDetailsFinalQr(processorId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("Activity Data :", data)
                const allActivityByProcessors = data.filter(item => item.pt_Product_id == this.ProductId);
                this.allActivityByProcessor = allActivityByProcessors.map(dailyItem => {
                    const matchingActivity = this.dailyData.find(activity => activity.pt_addactivity_id === dailyItem.pt_activity_id);
                    if (matchingActivity) {
                        return { ...dailyItem, ...matchingActivity };
                    } else {

                        return dailyItem;
                    }
                });

                console.log("Combined Activity Data :", this.allActivityByProcessor);

                allActivityByProcessors.forEach(activity => {
                    this.fetchActivityImage(activity.pt_activity_id);
                });
            }, (err: any) => {
                console.log("Something wrong :", err);
                this.spinner.hide();
                this.toaster.error("Something went wrong, Please try again later")
            })
    }

    getProcessorDetails(processorId: string) {
        this.spinner.show();
        this.appService.getProcessorDetailsFinalQr(processorId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.processorDetails = data[0];
                this.processorName = this.processorDetails.pt_processorlegalname;
                this.address = this.processorDetails.pt_processoraddressst1;
                this.city = this.processorDetails.pt_processorcity;
                this.state = this.processorDetails.pt_processorstate;
                this.country = this.processorDetails.pt_processorcountry;
                this.processorId = this.processorDetails.pt_processor_id;
                this.latitude = Number(this.processorDetails.latitude);
                this.longitude = Number(this.processorDetails.longtitude);
                const mapUrl = `https://maps.google.com/maps?q=${this.latitude},${this.longitude}&hl=es;z=14&output=embed`;
                this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl);
                this.mapView = true;
                this.spinner.hide();
            }, (err: any) => {
                console.log("Something wrong", err)
                this.spinner.hide();
                this.toaster.error("Something went wrong, Please try again later");
            })
    }

    isPastDate(activityDate: string): boolean {
        const currentDate = new Date();
        const activityEndDate = new Date(activityDate);
        return activityEndDate < currentDate;
    }

    getImage(processorId: string) {
        this.spinner.show();
        this.appService.getImageById(processorId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((blob) => {
                const reader = new FileReader()
                reader.onloadend = () => {
                    this.imageShow = reader.result as string;
                    const [, base64Data] = this.imageShow.split(',');
                    const fileType = this.imageShow.split(';')[0].split('/')[1];
                    console.log("Base64 Data:", base64Data);
                    console.log("File Type:", fileType);
                }
                reader.readAsDataURL(blob);
                this.spinner.hide();
            }, (error: any) => {
                console.log("Something Went Wrong:", error)
                this.spinner.hide();
            })
    }

    fetchActivityImage(activityId: string): void {
        this.spinner.show();
        this.appService.getImageById(activityId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.imageUrls[activityId] = reader.result as string;
                    };
                    reader.readAsDataURL(blob);
                    this.spinner.hide();
                },
                (error: any) => {
                    console.log("Something Went Wrong:", error);
                    this.spinner.hide();
                }
            );
    }

    @HostListener('click', ['$event.target'])
    onClick(target: any) {
        if (target.classList.contains('processor')) {
            this.scrollToProcessor();
        }
        if (target.classList.contains('birthplace')) {
            this.scrollToBirthplace();
        }
        if (target.classList.contains('journey')) {
            this.scrollToJourney();
        }
    }


    private scrollToProcessor() {
        const processorSection = document.getElementById('processor');

        if (processorSection) {
            const yOffset = processorSection.offsetTop;
            window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }
    }

    private scrollToBirthplace() {
        const birthplaceSection = document.getElementById('birthplace');

        if (birthplaceSection) {
            const yOffset = birthplaceSection.offsetTop;
            window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }
    }

    private scrollToJourney() {
        const journeySection = document.getElementById('journey');

        if (journeySection) {
            const yOffset = journeySection.offsetTop;
            window.scrollTo({ top: yOffset, behavior: 'smooth' });
        }
    }

    @HostListener('window:scroll', [])
    onScroll(): void {
        FinalQrComponent.checkScrollTopVisibility();
    }

    scrollToTopIcon(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    private static checkScrollTopVisibility(): void {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        const scrollButton = document.querySelector('.back-to-top-icon');
        if (scrollButton) {
            if (scrollTop > 300) { // Adjust this value based on when you want the button to appear
                scrollButton.classList.add('active');
            } else {
                scrollButton.classList.remove('active');
            }
        }
    }

    openGoogleMap(latitude: number, longitude: number) {
        let url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, '_blank');
    }
}
