import { Component, HostBinding, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { ProcessRegistration } from "@/model/processRegistration/processRegistration";
import { ActivityByProcessor } from "@/model/activityDetails/activityByProcessor";
import { ProductDetails } from "@/model/productDetails/productDetails";
import { Router } from "@angular/router";

@Component({
    selector: 'app-activity-mapping',
    templateUrl: './activity-mapping.component.html',
    styleUrls: ['./activity-mapping.component.scss']
})
export class ActivityMappingComponent implements OnInit {
    userType: string;
    processorDetails: ProcessRegistration[] = [];
    activityByProcessor: ActivityByProcessor[] = [];
    productByProcessor: ProductDetails[] = [];
    processorId: string = "";
    productId: string = "";

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private renderer: Renderer2, private router: Router,
        private toastr: ToastrService,
        private appService: AppService,
        private spinner: NgxSpinnerService
    ) {
    }

    activityMapDetails = new UntypedFormGroup({
        processorName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    })

    ngOnInit() {
        this.userType = localStorage.getItem('userType')
        this.getProcessorDetails();
    }

    getProcessorDetails() {
        if (this.userType == "Super Admin") {
            this.spinner.show();
            const createdById = localStorage.getItem('userId');
            this.appService.getProcessorRegistrationCreatedBy(createdById)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                    }
                    this.processorDetails = activeValues;
                }, (err: any) => {
                    console.log("something wrong :", err)
                    this.toastr.error("Something went wrong, Please try again later");
                    this.spinner.hide();
                })
        } else if (this.userType == "Processor") {
            this.spinner.show();
            const processorId = localStorage.getItem('userId');
            this.appService.getProcessorDetailsByProcessorId(processorId)
                .pipe(takeUntil(this.destroy$))
                .subscribe((data) => {
                    console.log("Processor Details :", data)
                    this.spinner.hide();
                    let activeValues: ProcessRegistration[] = [];
                    for (let status of data) {
                        if (status.pt_processoractive == "Active") {
                            activeValues.push(status);
                        }
                    }
                    this.processorDetails = activeValues;
                    this.activityMapDetails.controls['processorName'].setValue(this.processorDetails[0].pt_processor_id)
                    this.processorId = this.processorDetails[0].pt_processor_id;
                    this.getProductUsingCB();
                }, (err: any) => {
                    console.log("something wrong :", err)
                    this.toastr.error("Something went wrong, Please try again later");
                    this.spinner.hide();
                })
        }
    }

    selectProcessor(event: any) {
        const processorId = event.target.value;
        this.processorId = processorId
        if (this.processorId != "") {
            this.getProductUsingCB();
        }
    }

    getProductUsingCB() {
        this.spinner.show();
        this.appService.getProductUsingCB(this.processorId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Activity Details :", data)
                this.spinner.hide();
                this.productByProcessor = data;
            }, (err: any) => {
                console.log("something went wrong :", err)
                this.spinner.hide();
                this.toastr.error("Something went wrong, Please try again later");
            })
    }

    selectProduct(event: any) {
        const productId = event.target.value;
        this.productId = productId
        if (this.productId != "") {
            this.tableDataReload();
        }
    }

    buttonEnable(event: any, act: any) {
        act.buttonDisable = true;
    }

    mapFunction(act: any) {
        const mappingData = {
            processor_id: this.processorId,
            product_id: this.productId,
            activity_id: act.pt_activity_id,
            preactivity_id: act.pt_activity_prev_activity_id,
            nextactivity_id: act.pt_activity_next_activity_id,
            userid: localStorage.getItem('userId'),
        }
        console.log(mappingData)
        this.spinner.show();
        this.appService.addActivityMapping(mappingData)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                console.log("Mapped status :", data)
                this.toastr.success("Mapped Successfully");
                this.tableDataReload();
            }, (err: any) => {
                this.spinner.hide();
                this.toastr.error("Something went wrong, Please try again later");
                console.log("something went wrong :", err)
            })
    }

    tableDataReload() {
        this.appService.getActivityDetails(this.processorId, this.productId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Activity Details :", data)
                if (data.length <= 0) {
                    this.alertShowForNoActivity();
                }
                let permanentQr: string[] = [];
                for (let check of data) {
                    if (check.pt_activitydesc == null) {
                        permanentQr.push(check.pt_activity_id)
                    }
                }
                if (permanentQr.length >= 1) {
                    this.alertShow();
                } else {
                    this.activityByProcessor = data;
                }
                this.spinner.hide();
            }, (err: any) => {
                console.log("something went wrong :", err)
                this.spinner.hide();
                this.toastr.error("Something went wrong, Please try again later");
            })
    }

    alertShow() {
        Swal.fire({
            title: "Please update the all activity before mapping",
            confirmButtonText: "Okay",
        }).then((result) => {
            if (result.isConfirmed) {
                this.router.navigate(['/main/activity-creation'])
            }
        });
    }

    alertShowForNoActivity() {
        Swal.fire({
            title: "Please add activity for this product",
            confirmButtonText: "Okay"
        }).then((res) => {
            if (res.isConfirmed) {
                this.router.navigate(['/main/add-activity'])
            }
        })
    }

}
