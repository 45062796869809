<div class="container-fluid">
    <!--<div class="row col-md-12">
            <h4 class="ml-3 mt-3 text-bold">Activity Permanent QR Generation</h4>
        </div>-->
    <div class="row">
        <div class="col-md-3 mt-2">
            <form [formGroup]="processorDetails">
                <div class="col-md-12">
                    <div class="form-floating mb-2">
                        <select class="form-select" formControlName="pt_processorlegalname"
                            (click)="ShowProcessorDetails($event)">
                            <option selected hidden value="">Select Processor</option>
                            <option *ngFor="let name of LocalLegalName" [value]="name">{{name}}</option>
                        </select>
                        <label>Processor Name<span class="text-danger">*</span></label>
                    </div>
                </div>
            </form>
            <form [formGroup]="productDetails">
                <div class="col-md-12">
                    <div class="form-floating mb-2">
                        <select class="form-select" formControlName="pt_Productname"
                            (click)="getAlreadyCreatedActivityByProduct($event)">
                            <option selected hidden value="">Select Product</option>
                            <option *ngFor="let name of productName" [value]="name.productNumber">{{name.productName}}
                            </option>
                        </select>
                        <label>Product Name<span class="text-danger">*</span></label>
                    </div>
                </div>
            </form>
            <div class="col-md-12">
                <label class="text-bold ml-2">Activity Name<span class="text-danger">*</span></label>
                <div class="col-md-12">
                    <div class="form-check form-check-inline ml-1 mb-2" *ngFor="let pro of this.activityName">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="roast"
                            value="option1" (click)="currentActivity(pro.activityName,pro.activityNumber)">
                        <label class="form-check-label" for="roast">{{pro.activityName}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-9 mt-2" style="border-left: 3px solid #b7b7b7;">
            <form [formGroup]="activityDetails">
                <div class="row justify-content-around">
                    <div class="col-md-3">
                        <div class="form-floating mb-2">
                            <input type="text" class="form-control" formControlName="pt_activityaddressst1"
                                id="Activity ad1" placeholder="Address">
                            <label for="Activity ad1">Activity Address<span class="text-danger">*</span></label>
                            <span class="text-danger"
                                *ngIf="activityDetailsErrors.pt_activityaddressst1">{{activityDetailsErrors.pt_activityaddressst1}}</span>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-floating mb-2">
                            <select class="form-select" formControlName="pt_activitystate"
                                (change)="selectState($event)">
                                <option selected hidden value="">Select State</option>
                                <option *ngFor="let state of states" [value]="state">{{state}}</option>
                            </select>
                            <label>Activity State <span class="text-danger">*</span></label>
                            <span class="text-danger"
                                *ngIf="activityDetailsErrors.pt_activitystate">{{activityDetailsErrors.pt_activitystate}}</span>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-floating mb-2">
                            <select class="form-select" formControlName="pt_activitycity">
                                <option selected hidden value="">Select City</option>
                                <option *ngFor="let city of cities" [value]="city">{{city}}</option>
                            </select>
                            <label>Activity City <span class="text-danger">*</span></label>
                            <span class="text-danger"
                                *ngIf="activityDetailsErrors.pt_activitycity">{{activityDetailsErrors.pt_activitycity}}</span>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-around">
                    <div class="col-md-3">
                        <div class="form-floating mb-2">
                            <input type="text" class="form-control" formControlName="pt_activity_latitude"
                                id="Activity latitude" placeholder="Latitude">
                            <label for="Activity latitude">Activity Latitude <span class="text-danger">*</span> </label>
                            <span class="text-danger"
                                *ngIf="activityDetailsErrors.pt_activity_latitude">{{activityDetailsErrors.pt_activity_latitude}}</span>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-floating mb-2">
                            <input type="text" class="form-control" formControlName="pt_activity_longtitude"
                                id="Activity longitude" placeholder="Longitude">
                            <label for="Activity longitude">Activity Longitude <span class="text-danger">*</span>
                            </label>
                            <span class="text-danger"
                                *ngIf="activityDetailsErrors.pt_activity_longtitude">{{activityDetailsErrors.pt_activity_longtitude}}</span>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-floating mb-2">
                            <input type="text" class="form-control" formControlName="pt_shortdesc"
                                id="pt_ActivityShortDesc" placeholder="Short Description">
                            <label>Activity Short Desc <span class="text-danger">*</span> </label>
                            <span class="text-danger"
                                *ngIf="activityDetailsErrors.pt_shortdesc">{{activityDetailsErrors.pt_shortdesc}}</span>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-around">
                    <div class="col-md-3">
                        <div class="form-floating mb-3">
                            <textarea class="form-control" placeholder="Description" id="description"
                                formControlName="pt_activitydesc" style="height: 100px"></textarea>
                            <label for="description">Activity Description <span class="text-danger">*</span></label>
                            <span class="text-danger"
                                *ngIf="activityDetailsErrors.pt_activitydesc">{{activityDetailsErrors.pt_activitydesc}}</span>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-floating mb-3">
                            <input type="file" class="form-control" formControlName="selectedFile"
                                (change)="onFileChange($event)">
                            <label>Activity Icon<span class="text-danger">*</span> </label>
                            <span class="text-danger"
                                *ngIf="activityDetailsErrors.selectedFile">{{activityDetailsErrors.selectedFile}}</span>
                        </div>
                    </div>

                    <div class="col-md-3 d-flex justify-content-end">
                        <div *ngIf="isUpdate" class="col-md-12">
                            <img [src]="imageShow" alt="Activity Icon" style="width: 150px">
                        </div>
                    </div>
                </div>

                <!--<div class="col-md-3">
                    <div class="field-flex">
                        <div class="form-floating mb-3">
                            <input type="password" class="form-control" placeholder="secret code"
                                   formControlName="pt_activity_seccode" id="code" readonly>
                            <label for="code">Secret Code</label>
                        </div>
                        <div class="form-floating mb-3">
                        <span style="background-color: #e9ecef" class="form-control" type="button">
                            <i class="fa fa-eye" id="eye" data-placement="top"
                               title="Click to Show Password" (click)="togglePasswordVisibility()"
                               [ngClass]="{'fa-eye-slash': isPasswordVisible, 'fa-eye': !isPasswordVisible}"
                               aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>-->

                <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-danger" (click)="formValidation()">{{this.isUpdate == true ?
                        "Update" : "Save"}}</button>
                    <button class="btn btn-danger ml-2" type="button" [disabled]="isQRcode"
                        (click)="QRcodeGenration()">Generate QR Code
                    </button>
                </div>

                <div class="col-12 mt-3">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="table-responsive" style="height: 250px">
                                <table class="table table-bordered">
                                    <thead class="text-center sticky-top">
                                        <tr style="background-color: #e7f2f8">
                                            <th class="p-1">No</th>
                                            <th class="p-1">Activity Name</th>
                                            <th class="p-1">Permanent QR</th>
                                            <th class="p-1">Download QR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center" *ngFor="let act of getActivityDetails; let i = index">
                                            <td>{{i + 1}}</td>
                                            <td>{{act.pt_activityname}}</td>
                                            <td>
                                                <div *ngIf="act.pt_addactivitymodifiedby != null">Created</div>
                                                <div *ngIf="act.pt_addactivitymodifiedby == null">Not Created</div>
                                            </td>
                                            <td>
                                                <div *ngIf="act.pt_addactivitymodifiedby != null"><i
                                                        (click)="downloadActivityQR(act.pt_activity_id)"
                                                        class="fa fa-download"></i></div>
                                                <div *ngIf="act.pt_addactivitymodifiedby == null"> - </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal" tabindex="-1" [style.display]="isViewShow ? 'block' : 'none'">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header qrPopup-background">
                <h4 class="modal-title">QR Code for {{currentActivityType}} Activity</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="close()"></button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <img src="{{qrCodeUrl}}">
                </div>
            </div>
            <div class="modal-footer qrPopup-background">
                <button type="button" class="btn btn-primary" (click)="downloadQrCode()">Download</button>
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="close()">Close</button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>