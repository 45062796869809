<app-header class="sticky-top"></app-header>

<app-menu-sidebar class="sidebar-dark-primary" style="background-color: #855353; position: fixed"></app-menu-sidebar>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-footer class="fixed-bottom"></app-footer>
<app-control-sidebar></app-control-sidebar>
<div (click)="onToggleMenuSidebar()" id="sidebar-overlay"></div>
