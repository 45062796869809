<ul class="navbar-nav">
  <li class="nav-item">
    <a (click)="onToggleMenuSidebar()" class="nav-link" role="button"
    ><i class="fas fa-bars"></i
    ></a>
  </li>
  <!--<li class="nav-item d-none d-sm-inline-block">
    <a [routerLink]="['/']" class="nav-link">Home</a>
  </li>
  <li class="nav-item d-none d-sm-inline-block">
    <a [routerLink]="['/blank']" class="nav-link">Contact</a>
  </li>-->
</ul>

<form *ngIf="false" [formGroup]="searchForm" class="form-inline ml-3">
  <div class="input-group input-group-sm">
    <input
      aria-label="Search"
      class="form-control form-control-navbar"
      placeholder="Search"
      type="search"
    />
    <div class="input-group-append">
      <button class="btn btn-navbar" type="submit">
        <i class="fas fa-search"></i>
      </button>
    </div>
  </div>
</form>

<ul class="navbar-nav ml-auto">
  <!--<app-messages></app-messages>
  <app-notifications></app-notifications>
  <app-language></app-language>-->
  <img src="assets/img/reload.png" title="Reload" (click)="reload()" class="mt-1 mr-1" style="height: 33px">
  <app-user></app-user>
  <!--<li class="nav-item">
    <button (click)="onToggleControlSidebar()" class="nav-link">
      <i class="fas fa-th-large"></i>
    </button>
  </li>-->
</ul>
