<div class="container-fluid">

    <!--Geo-Tracing page-->
    <section class="geo"
        style="background-image: linear-gradient(180deg,rgb(255,240,123),rgb(255,255,185)); width: 100%">
        <div class="row mt-1">
            <div class="col-lg-12 text-center">
                <img src="assets/img/geotracing.png" class="img-fluid h-75">
            </div>
        </div>

        <div class="row">
            <div class="col-4 text-center">
                <img src="assets/img/processor_icon.png" id="img1" class="icons processor">
            </div>
            <div class="col-4 text-center">
                <img src="assets/img/birthplace.png" id="img2" class="icons birthplace">
            </div>
            <div class="col-4 text-center">
                <img src="assets/img/journey.png" id="img3" class="icons journey">
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-5 text-center">
                <img [src]="imageShow" class="img-style" style="object-fit: contain">
            </div>
            <div class="col-7 text-center align-center">
                <h4 class="madimi-one">{{processorName}}</h4>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-6 text-center">
                <img src="assets/img/gi%20logo.png" class="img-fluid" style="height: 135px; width: 135px;object-fit: contain;">
            </div>
            <div class="col-6 text-center align-center">
                <a href="assets/img/gi-certificate.jpeg" target="_blank">
                    <img src="assets/img/mittai.png" class="img-style img-fluid">
                </a>
            </div>
        </div>

        <footer class="mt-5">
            <div class="text-center">
                <h6 class="text-dark">Ⓒ {{currentYear}} TraceFarm2Table. All Rights Reserved. Designed &
                    Developed by <a href="https://varnik.cloud/" target="_blank"
                        class="text-primary">VarnikCloud.com</a></h6>
            </div>
        </footer>
    </section>

    <!--My Processor page-->
    <section id="processor" class="process mt-2"
        style="background-image: linear-gradient(180deg,rgb(255,240,123),rgb(255,255,185))">
        <div class="row">
            <div class="col-4 text-center">
                <img src="assets/img/processor_icon.png" class="icons mt-3">
            </div>
            <div class="col-8 mt-3">
                <h1 class="text-center madimi-one mt-3">My Processor</h1>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-10 ml-5">
                <h2>{{processorName}}</h2>
            </div>
            <div class="col-10 ml-5">
                <h6>{{address}},</h6>
            </div>
            <div class="col-10 ml-5 address">
                <h6>{{city}},</h6>
            </div>
            <div class="col-10 ml-5 address">
                <h6>{{state}},</h6>
            </div>
            <div class="col-10 ml-5 address">
                <h6>{{country}}.</h6>
            </div>
            <div class="col-12 mb-4 text-center">
                <img [src]="imageShow" class="img-style">
            </div>
        </div>
        <footer class="mt-5">
            <div class="text-center">
                <h6 class="text-dark">Ⓒ {{currentYear}} TraceFarm2Table. All Rights Reserved. Designed &
                    Developed by <a href="https://varnik.cloud/" target="_blank"
                        class="text-primary">VarnikCloud.com</a></h6>
            </div>
        </footer>
    </section>

    <!--My Birthplace page-->
    <section id="birthplace" class="map mt-2"
        style="background-image: linear-gradient(180deg,rgb(255,240,123),rgb(255,255,185))">
        <div class="row">
            <div class="col-4 text-center">
                <img src="assets/img/birthplace.png" class="icons mt-3">
            </div>
            <div class="col-8 mt-3">
                <h1 class="text-center madimi-one mt-3">My Birthplace</h1>
            </div>
        </div>
        <div class="row mt-5"></div>
        <div *ngIf="mapView" class="container-md container-fluid col-lg-10 text-center">
            <div class="col-12 mb-3 ">
                <iframe [src]="mapUrl" width="100%" style="height: 500px"></iframe>
            </div>
        </div>

        <div class="row mt-5"></div>
        <footer class="mt-5">
            <div class="text-center">
                <h6 class="text-dark">Ⓒ {{currentYear}} TraceFarm2Table. All Rights Reserved. Designed &
                    Developed by <a href="https://varnik.cloud/" target="_blank"
                        class="text-primary">VarnikCloud.com</a></h6>
            </div>
        </footer>
    </section>

    <!--My Journey page-->
    <section id="journey" class="travel mt-2"
        style="background-image: linear-gradient(180deg,rgb(255,240,123),rgb(255,255,185))">
        <div class="row">
            <div class="col-4 text-center">
                <img src="assets/img/journey.png" class="icons mt-3">
            </div>
            <div class="col-8 mt-3">
                <h1 class="text-center madimi-one mt-3">My Journey</h1>
            </div>
        </div>
        <div class="row"></div>
        <div class="col-md-12" *ngFor="let act of allActivityByProcessor; let i=index; let last=last">
            <div class="row" *ngIf="evenValues.includes(i)">
                <div class="col-5 col-lg-3 align-center text-center animate fadeInLeft three">
                    <img [src]="imageUrls[act.pt_addactivity_id]" class="img-style">
                </div>

                <!--<div class="col-3 col-lg-2 align-center animate fadeIn five5">
                    <img *ngIf="!last" src="assets/img/Animation%20-%203.gif">
                </div>-->

                <div class="col-7 col-lg-9 animate fadeInRight seven py-3">
                    <p class="text-start" style="color: #041e59;">My {{act.pt_activityname}} Starting At
                        {{act.pt_activitydailystarttime | date: 'dd-MM-yyyy HH:mm' }}
                        and Ending At {{act.pt_activitydailyendtime | date: 'dd-MM-yyyy HH:mm' }}<span>
                            <img class="img-fluid ms-2" style="width: 20px" src="assets/img/geo.png"
                                (click)="openGoogleMap(act.pt_activity_latitude, act.pt_activity_longtitude)">
                        </span></p>
                </div>
                <div class="row justify-content-center gif" *ngIf="i !== allActivityByProcessor.length - 1">
                    <!-- <div class="col-lg-6"></div> -->
                    <img src="../../../assets/img/smartparcel-down-arrows.gif" class="icons" style="width: 70px;height: 60px;">
                </div>
            </div>
            <div class="row" *ngIf="addValues.includes(i)">
                <div class="col-7 col-lg-9 animate fadeInLeft eight py-3">
                    <p class="text-start" style="color: #041e59;">My {{act.pt_activityname}} Starting At
                        {{act.pt_activitydailystarttime | date: 'dd-MM-yyyy HH:mm' }}
                        and Ending At {{act.pt_activitydailyendtime | date: 'dd-MM-yyyy HH:mm' }}<span>
                            <img class="img-fluid ms-2" style="width: 20px" src="assets/img/geo.png"
                                (click)="openGoogleMap(act.pt_activity_latitude,act.pt_activity_longtitude)">
                        </span>
                </div>

                <!--<div class="col-3 col-lg-2 align-center animate fadeIn six6">
                    <img *ngIf="!last" src="assets/img/Animation%20-%202.gif">
                </div>-->

                <div class="col-5 col-lg-3 align-center text-center animate fadeInRight four">
                    <img [src]="imageUrls[act.pt_addactivity_id]" class="img-style">
                </div>
                <div class="row justify-content-center gif" *ngIf="i !== allActivityByProcessor.length - 1">
                    <img src="../../../assets/img/smartparcel-down-arrows.gif" class="icons" style="width: 70px;height: 60px;">
                </div>
            </div>
        </div>

        <!--<div class="row">
            <div class="col-6 col-lg-7 animate fadeInLeft eight">
                <p class="text-end" style="color: #041e59;">My Mixing Process Started at 2022-03-18 10:30:01.0 at the
                    Facility AKR,Thoothukudi Dist,Tamilnadu <span> <img class="img-fluid" style="width: 20px"
                                                                        src="assets/img/geo.png"></span></p>
            </div>

            <div class="col-3 col-lg-2 mt-5 mt-lg-3 animate fadeIn six6">
                <img src="assets/img/Animation%20-%202.gif" class="mt-5 mt-lg-0">
            </div>

            <div class="col-3 text-start animate fadeInRight four">
                <img src="assets/img/baking.png" class="img-fluid mt-4 mt-lg-0">
            </div>
        </div>

        <div class="row">
            <div class="col-3 text-center animate fadeInLeft five">
                <img src="assets/img/ham.png" class="img-fluid mt-4 mt-lg-0">
            </div>

            <div class="col-3 col-lg-2 mt-5 mt-lg-3 animate fadeIn seven7">
                <img src="assets/img/Animation%20-%203.gif" class="mt-5 mt-lg-0">
            </div>

            <div class="col-6 col-lg-7 animate fadeInRight nine">
                <p class="text-start" style="color: #041e59;">My Slicing Process Started at 2022-03-18 13:30:01.0 at the
                    Facility AKR,Thoothukudi Dist,Tamilnadu <span> <img class="img-fluid" style="width: 20px"
                                                                        src="assets/img/geo.png"></span></p>
            </div>
        </div>

        <div class="row">
            <div class="col-6 col-lg-7 animate fadeInLeft ten">
                <p class="text-end" style="color: #041e59;">My Packing Process Started at 2022-03-18 08:30:59.0 at the
                    Facility AKR,Thoothukudi Dist,TamilNadu <span> <img class="img-fluid" style="width: 20px"
                                                                        src="assets/img/geo.png"></span></p>
            </div>

            <div class="col-3 col-lg-2"></div>

            <div class="col-3 text-start animate fadeInRight six">
                <img src="assets/img/box.png" class="img-fluid mt-4 mt-lg-0">
            </div>
        </div>-->

        <footer class="mt-5">
            <div class="text-center">
                <h6 class="text-dark">Ⓒ {{currentYear}} TraceFarm2Table. All Rights Reserved. Designed &
                    Developed by <a href="https://varnik.cloud/" target="_blank"
                        class="text-primary">VarnikCloud.com</a></h6>
            </div>
        </footer>
    </section>
</div>
<a class="back-to-top-icon d-flex align-items-center justify-content-center active" type="button"
    (click)="scrollToTopIcon()"><i class="fas fa-arrow-up"></i></a>