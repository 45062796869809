<div class="container-fluid py-3">
    <!-- <div class="row">
        <div class="col-lg-5"></div>
        <div class="col-lg-5 mt-5">
            <h3 >Processor Management</h3>
        </div>
    </div> -->
    <form [formGroup]="processorDetails" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="pt_processorlegalname"
                           placeholder="Legal Name">
                    <label>Legal Name<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processorlegalname">{{processorDetailsErrors.pt_processorlegalname}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="pt_processornickname"
                           placeholder="Nick Name">
                    <label>Nick Name<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processornickname">{{processorDetailsErrors.pt_processornickname}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="pt_processoraddressst1"
                           placeholder="Address ST1">
                    <label>Address<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processoraddressst1">{{processorDetailsErrors.pt_processoraddressst1}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <select class="form-select" formControlName="pt_processorcountry" (change)="selectCountry($event)">
                        <option selected hidden value="">Select Country</option>
                        <option *ngFor="let country of countries" [value]="country.shortName">{{country.name}}</option>
                    </select>
                    <label>Country<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processorcountry">{{processorDetailsErrors.pt_processorcountry}}</span>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <select class="form-select" formControlName="pt_processorstate" (change)="selectState($event)">
                        <option selected hidden value="">Select State</option>
                        <option *ngFor="let state of states" [value]="state">{{state}}</option>
                    </select>
                    <label>State<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processorstate">{{processorDetailsErrors.pt_processorstate}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <select class="form-select" formControlName="pt_processorcity">
                        <option selected hidden value="">Select City</option>
                        <option *ngFor="let city of cities" [value]="city">{{city}}</option>
                    </select>
                    <label>City<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processorcity">{{processorDetailsErrors.pt_processorcity}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="tel" class="form-control" formControlName="pt_processor_phonenumber"
                           placeholder="Phone Number"
                           (keypress)="numberOnly($event)" maxlength="10">
                    <label>Phone Number<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processor_phonenumber">{{processorDetailsErrors.pt_processor_phonenumber}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" placeholder="Objective"
                           formControlName="pt_processorobjective">
                    <label>Objective<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processorobjective">{{processorDetailsErrors.pt_processorobjective}}</span>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="ml_sequence_char" placeholder="ML/MT Code">
                    <label>Magic Label / Tag Code </label>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="email" class="form-control" formControlName="pt_processor_emailid" id="email"
                           placeholder="Email ID">
                    <label for="email">Email ID<span class="text-danger">*</span></label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.pt_processor_emailid">{{processorDetailsErrors.pt_processor_emailid}}</span>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="pt_website_link" placeholder="Website">
                    <label>Website</label>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="latitude" placeholder="Latitude">
                    <label>Latitude<span class="text-danger">*</span> </label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.latitude">{{processorDetailsErrors.latitude}}</span>
                </div>
            </div>
        </div>

        <div class="row ">
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control" formControlName="longtitude" placeholder="Longitude">
                    <label>Longitude<span class="text-danger">*</span> </label>
                    <span class="text-danger"
                          *ngIf="processorDetailsErrors.longtitude">{{processorDetailsErrors.longtitude}}</span>
                </div>
            </div>
            <div *ngIf="isAdmin" class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <select class="form-select" id="active" formControlName="pt_processoractive">
                        <option selected hidden value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                    </select>
                    <label for="active">Active Status </label>
                </div>
            </div>
            <div class="col-lg-3 col-12">
                <div class="form-floating mb-3">
                    <input type="file" class="form-control" formControlName="selectFile" placeholder="Upload Logo" (change)="onFileChange($event)">
                    <label for="active">Organization Icon</label>
                </div>
            </div>
            <div *ngIf="!isReadOnly" class="col-lg-3 col-12">
                <div class="">
                    <img [src]="imageShow" alt="Organization Logo" style="width: 150px">
                </div>
            </div>
        </div>

        <!--<div class="row">
            <div class="col-lg-3">
              <div class="form-floating input-group-prepend">
                <input type="password" class="form-control" formControlName="pt_processor_password" placeholder="Password" id="pt_processor_password">
                <label>Password<span class="text-danger">*</span></label>
                <span class="input-group-text"><i data-toggle="tooltip" data-placement="top" title="Click to Show Password"
                  class="toggle-password fa fa-lock" (click)="togglePasswordVisibility()" 
                  [ngClass]="{'fa-lock-open': isPasswordVisible, 'fa-lock': !isPasswordVisible}" aria-hidden="true"></i></span>
              </div>
              <span class="text-danger d-flex justify-content-start"
              *ngIf="processorDetailsErrors.pt_processor_password">{{processorDetailsErrors.pt_processor_password}}</span>
            </div>
        </div>-->
        <div class="row mt-1 mb-3 text-center">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <!-- <input class="btn btn-danger" type="button" value="Submit">
                <input class="btn btn-danger ml-2" type="button" value="Edit"> -->
                <button class="btn btn-danger ml-2" type="button" (click)="formValidation()" [disabled]="isReadOnly">
                    Update
                </button>
                <button class="btn btn-danger ml-2" type="button" (click)="cencel()" [disabled]="isReadOnly">Cancel
                </button>
            </div>
        </div>
    </form>

    <div class="row mt-2">
        <div class="col-lg-2"></div>
        <div class="col-lg-8">

        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead class="sticky-top border-0">
                <tr class="text-center" style="background-color: #e7f2f8">
                    <th></th>
                    <th>Legal Name</th>
                    <th>Phone Number</th>
                    <th>Emai ID</th>
                    <th>Active Status</th>
                </tr>
                </thead>
                <tbody *ngFor="let pro of this.getProDetails">
                <tr class="text-center">
                    <td>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox"
                                   (change)="selectCheckbox(pro.pt_processor_id)"
                                   [checked]="markId === pro.pt_processor_id">
                            <label class="form-check-label"> </label>
                        </div>
                    </td>

                    <td>{{pro.pt_processorlegalname}}</td>
                    <td>{{pro.pt_processor_phonenumber}}</td>
                    <td>{{pro.pt_processor_emailid}}</td>
                    <td>{{pro.pt_processoractive}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>