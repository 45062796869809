import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {Subject, takeUntil} from "rxjs";
import {LoginResponse} from "@/modals/loginResponse";
import {Router} from "@angular/router";
import {DateTime} from 'luxon';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginResponse: LoginResponse | undefined;
  isPasswordVisible: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public currentYear: string = DateTime.now().toFormat('y');

  constructor(
    private renderer: Renderer2, private router: Router,
    private toastr: ToastrService,
    private appService: AppService
  ) {
  }

  ngOnInit() {
  }

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    password: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
  });

  onSubmit() {
    let hasError = false

    if (this.loginForm.get('username').invalid) {
      hasError = true;
    }
    if (this.loginForm.get('password').invalid) {
      hasError = true
    }

    if (!hasError) {
      this.loginAuth();
    } else {
      this.toastr.error('Please enter the credential');
    }
  }

  loginAuth() {
    console.log(this.loginForm.value);
    this.appService.loginAuth(this.loginForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          console.log("login data :", data)
          this.loginResponse = data;
          localStorage.setItem('token', this.loginResponse.authHeader);
          localStorage.setItem('userType', this.loginResponse.loginDto.pt_userrole);
          localStorage.setItem('userId', this.loginResponse.loginDto.pt_user_id);
          this.router.navigate(['/main/dash'])
          this.toastr.success('Login Successfully')
        }, (err: any) => {
          console.log("Something went wrong", err)
          if (err.status == 401) {
            this.toastr.error("Incorrect credential")
          } else {
            this.toastr.error("Something went wrong, Please try again later");
          }
        })
  }

  ngOnDestroy() {
  }

  togglePasswordVisibility() {
    const passwordShow = document.getElementById(
      'password'
    ) as HTMLInputElement;
    if (passwordShow.type == 'password') {
      passwordShow.type = 'text';
    } else {
      passwordShow.type = 'password';
    }
    this.isPasswordVisible = !this.isPasswordVisible;
  }


  register() {
    this.router.navigate(['/register'])
  }
}
