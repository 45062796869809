<div class="container-fluid">
    <div class="col-md-12 row">
        <h3 class="mt-3">Qr Code Generation</h3>
    </div>
    <div class="row">
        <div class="col-md-3 mt-3">
            <div class="form-floating mb-3">
                <select class="form-select" id="name">
                    <option selected hidden value="">Select Processor</option>
                    <option *ngFor="let pro of processorDetails"
                            value="{{pro.pt_processor_id}}">{{pro.pt_processorlegalname}}</option>
                </select>
                <label for="name">Name<span class="text-danger">*</span></label>
            </div>
        </div>

        <div class="col-md-3 mt-3">
            <div class="form-floating mb-3">
                <select class="form-select" id="batchCode">
                    <option selected hidden value="">Select Batch Code</option>
                    <!--<option *ngFor="let pro of processorDetails"
                            value="{{pro.pt_processor_id}}">{{pro.pt_processorlegalname}}</option>-->
                </select>
                <label for="name">Batch Code<span class="text-danger">*</span></label>
            </div>
        </div>

        <div class="col-md-3 mt-3">
            <div class="form-floating mb-3">
                <input class="form-control" placeholder="Enter Qr Count" (change)="getQrCount($event)">
                <label for="name">Qr Count<span class="text-danger">*</span></label>
            </div>
        </div>

        <div class="col-md-3 mt-3 text-center">
            <button class="btn btn-success mt-2" (click)="generateQRCode()">Generate</button>
        </div>
    </div>
</div>

<div #qrCodeContainer></div>

<ngx-spinner>
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>