import {Component, HostBinding, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppService} from '@services/app.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import {Country} from "countrycitystatejson/src/countries-list";

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    isPasswordVisible: any;
    destroy$: Subject<boolean> = new Subject<boolean>();
    countries: Country[];
    states: string[];
    cities: string[];


    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private spinner: NgxSpinnerService, private router: Router
    ) {
        this.countries = this.appService.getCountries();
    }

    ngOnInit() {
        this.newRegistrion.controls['pt_processorcreatedby'].setValue('PTU-000002');
        this.newRegistrion.controls['pt_processoractive'].setValue('Inactive')
        this.newRegistrion.controls['deleteyn'].setValue('No');
    }

    selectCountry(event: any) {
        const country = event.target.value;
        console.log(country)
        if (country) {
            this.states = this.appService.getStatesByCountry(country);
        }
    }

    selectState(event: any) {
        const state = event.target.value;
        const country = this.newRegistrion.get('pt_processorcountry').value
        console.log(state)
        if (state && country) {
            this.cities = this.appService.getCitiesByState(country, state);
        }
    }


    newRegistrion = new UntypedFormGroup({
        pt_processor_id: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorlegalname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processornickname: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst1: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst2: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraddressst3: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorstate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcountry: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorobjective: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoractive: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcreatedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processorcreateddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processormodifiedby: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processormodifieddate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processor_emailid: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processor_phonenumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern(/^\d{10}$/)]),
        pt_processor_password: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        activeyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        deleteyn: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        ml_sequence_char: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_authenticate_code: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_website_link: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        latitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        longtitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        assetMetaData: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        assetFileType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        selectedFile: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        pt_processoraboutme: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    newRegistrionErrors = {
        pt_processorlegalname: "",
        pt_processornickname: "",
        pt_processoraddressst1: "",
        pt_processorcity: "",
        pt_processorstate: "",
        pt_processorcountry: "",
        pt_processor_phonenumber: "",
        pt_processorobjective: "",
        pt_processor_emailid: "",
        latitude: "",
        longtitude: "",
        pt_processor_password: "",
        selectedFile: "",
        pt_processoraboutme: ""
    }

    onSubmit() {
        this.formValidation()
    }

    formValidation() {
        this.newRegistrionErrors.pt_processorlegalname = "";
        this.newRegistrionErrors.pt_processornickname = "";
        this.newRegistrionErrors.pt_processoraddressst1 = "";
        this.newRegistrionErrors.pt_processorcity = "";
        this.newRegistrionErrors.pt_processorstate = "";
        this.newRegistrionErrors.pt_processorcountry = "";
        this.newRegistrionErrors.pt_processor_phonenumber = "";
        this.newRegistrionErrors.pt_processorobjective = "";
        this.newRegistrionErrors.pt_processor_emailid = "";
        this.newRegistrionErrors.latitude = "";
        this.newRegistrionErrors.longtitude = "";
        this.newRegistrionErrors.pt_processor_password = "";
        this.newRegistrionErrors.selectedFile = "";
        this.newRegistrionErrors.pt_processoraboutme = "";

        let hasError = false;

        if (this.newRegistrion.get('pt_processorlegalname').invalid) {
            this.newRegistrionErrors.pt_processorlegalname = "*Enter legal name";
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processornickname').invalid) {
            this.newRegistrionErrors.pt_processornickname = "*Enter nick name";
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processoraddressst1').invalid) {
            this.newRegistrionErrors.pt_processoraddressst1 = "*Enter address";
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processorcity').invalid) {
            this.newRegistrionErrors.pt_processorcity = "*Select city";
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processorstate').invalid) {
            this.newRegistrionErrors.pt_processorstate = "*Select state";
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processorcountry').invalid) {
            this.newRegistrionErrors.pt_processorcountry = "*Select country";
            hasError = true;
        }

        if (this.newRegistrion.get("pt_processor_phonenumber").invalid) {
            if (this.newRegistrion.get("pt_processor_phonenumber").errors?.["required"]) {
                this.newRegistrionErrors.pt_processor_phonenumber = "*Enter phone number";
            }
            if (this.newRegistrion.get("pt_processor_phonenumber")?.getError("pattern")) {
                this.newRegistrionErrors.pt_processor_phonenumber = "*Enter 10-digit phone number";
            }
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processorobjective').invalid) {
            this.newRegistrionErrors.pt_processorobjective = "*Enter objective";
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processor_emailid').invalid) {
            this.newRegistrionErrors.pt_processor_emailid = "*Enter email id";
            hasError = true;
        }

        if (this.newRegistrion.get('latitude').invalid) {
            this.newRegistrionErrors.latitude = "*Enter latitude";
            hasError = true;
        }

        if (this.newRegistrion.get('longtitude').invalid) {
            this.newRegistrionErrors.longtitude = "*Enter longitude";
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processor_password').invalid) {
            this.newRegistrionErrors.pt_processor_password = "*Enter password";
            hasError = true;
        }

        if (this.newRegistrion.get('selectedFile').invalid) {
            this.newRegistrionErrors.selectedFile = "*Upload the icon";
            hasError = true;
        }

        if (this.newRegistrion.get('pt_processoraboutme').invalid) {
            this.newRegistrionErrors.pt_processoraboutme = "*Enter about me";
            hasError = true;
        }

        if (!hasError) {
            this.saveRegistrion()
        }

    }


    numberOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    saveRegistrion() {
        this.spinner.show()
        this.newRegistrion.controls['activeyn'].setValue('Yes');
        this.newRegistrion.controls['deleteyn'].setValue('No');
        console.log("saveDetailsGet::", this.newRegistrion.value)
        this.appService.saveProcessorRegistrion(this.newRegistrion.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide()
                console.log("GetSaveDetails::", data)
                this.alertShow();
            }, (error: any) => {
                this.spinner.hide()
                this.errorAlert();
                console.log("Something Went Wrong:", error)
            })
    }

    togglePasswordVisibility() {
        const passwordShow = document.getElementById(
            'password'
        ) as HTMLInputElement;
        if (passwordShow.type == 'password') {
            passwordShow.type = 'text';
        } else {
            passwordShow.type = 'password';
        }
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    alertShow() {
        Swal.fire({
            title: "Registration details have been saved successfully. Once the admin verifies your registration, you will be able to log in.",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.spinner.show();
                this.router.navigate(['/login'])
                this.spinner.hide();
            }
        });
    }

    errorAlert() {
        Swal.fire({
            title: "Sorry, Please Try Again Your Registration Process",
            icon: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate_animated animate_fadeInDown",
            },
            hideClass: {
                popup: "animate_animated animate_fadeOutUp",
            },
        });
    }

    onFileChange(event: any) {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result as string;
                const [, base64Data] = base64String.split(',');
                const fileType = base64String.split(';')[0].split('/')[1];
                console.log("Base64 Data:", base64Data);
                console.log("File Type:", fileType);
                this.newRegistrion.controls['assetFileType'].setValue(fileType);
                this.newRegistrion.controls['assetMetaData'].setValue(base64Data);
            };
            reader.readAsDataURL(selectedFile);
        }
    }
}
