import {Component, OnInit} from '@angular/core';
import {interval, Subject, Subscription} from 'rxjs';
import * as QRCode from 'qrcode';
import {Router} from "@angular/router";

@Component({
    selector: 'app-final-label-print',
    templateUrl: './final-label-print.component.html',
    styleUrls: ['./final-label-print.component.scss']
})
export class FinalLabelPrintComponent implements OnInit {
    batchCode: string;
    labelCount: number;
    productId: string;
    private intervalSubscription: Subscription;
    finalQrCodeUrl: string = "https://geotracing.varnikcloud.com/#/final-qr?batchCode=";
    qrCodeDataUrl: string;
    qrCodeCountList: string[] = [];

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.batchCode = sessionStorage.getItem('batchCode');
        this.productId = sessionStorage.getItem('productId');
        this.labelCount = Number(sessionStorage.getItem('labelCount'));
        this.generateQRCode()
    }

    generateQRCode() {
        const finalQrCodeUrl = this.finalQrCodeUrl + this.batchCode + "&productId=" + this.productId;

        this.generateQRCodeUrl(finalQrCodeUrl)
            .then(() => {
            })
            .catch((error) => {
            });
    }

    generateQRCodeUrl(finalQrCodeUrl: string): Promise<void> {
        return new Promise((resolve, reject) => {
            QRCode.toDataURL(finalQrCodeUrl, (error, qrCodeDataUrl) => {
                if (error) {
                    console.error('Error generating QR code:', error);
                    reject(error);
                } else {
                    this.qrCodeDataUrl = qrCodeDataUrl;
                    console.log('QR Code Data URL:', qrCodeDataUrl);
                    resolve();
                    this.qrCodeList();
                }
            });
        });
    }

    qrCodeList() {
        for (let i = 0; i < this.labelCount; i++) {
            this.qrCodeCountList.push(this.qrCodeDataUrl);
        }
        this.intervalSubscription = interval(2000).subscribe(() => {
            this.print();
        });
    }

    print() {
        const originalTitle = document.title;
        document.title = this.batchCode;
        window.print();
        document.title = originalTitle;
        this.intervalSubscription.unsubscribe();

        setTimeout(() => {
            this.router.navigate(['/main/final-activity']);
        })
    }
}
