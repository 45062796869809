import {Injectable, OnInit} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from '@services/app.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    role: string = "";

    constructor(private router: Router, private appService: AppService) {
    }

    ngOnInit(): void {
        this.role = localStorage.getItem("userType");
        console.log("role :", this.role);
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.getProfile();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.canLoad(state.url)
        return this.canActivate(next, state);
    }

    async getProfile() {
        if (this.appService.user) {
            return true;
        }

        try {
            await this.appService.getProfile();
            return true;
        } catch (error) {
            return false;
        }
    }


    canLoad(
        route: string
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        if (route == "/login") {
            if (this.role == "") {
                this.router.navigate(["/login"]);
                return true;
            } else {
                this.router.navigate(["/main"]);
                return true;
            }
        }

        this.role = localStorage.getItem("userType");
        console.log("role :", this.role);

        switch (this.role) {
            case "Processor":
                this.departmentFilter(route);
                break;
            case "Super Admin":
                //this.router.navigate(['/main/dash']);
                return false;
            default :
                this.router.navigate(["/"]);
                break;
        }
    }

    departmentFilter(route: string) {
        console.log("route::", route);
        switch (this.role) {
            case "Processor": {
                if (route == "/main/activity-creation" || route == "/") {
                    return true;
                } else if (route == "/main/dash") {
                    this.router.navigate(["/main/add-activity"]);
                    return true;
                } else if (route == "/main/activity-mapping" || route == "/") {
                    return true;
                } else if (route == "/main/daily-activity-creation" || route == "/") {
                    return true;
                } else if (route == "/main/add-activity" || route == "/") {
                    return true;
                } else if (route == "/main/qr-generation" || route == "/") {
                    return true;
                } else if (route == "/main/processor-management" || route == "/") {
                    return true;
                } else if (route == "/main/final-activity" || route == "/") {
                    return true;
                } else {
                    this.router.navigate(["/"]);
                    return false;
                }
                break;
            }
            default: {
                //statements;
                break;
            }
        }
    }
}
